.toaster_modal {
    box-sizing: border-box;
    position: fixed;
    right: 10px;
    top: 20px;
    border-radius: 8px;
    z-index: 100;
}

.toaster_modal_subHeader {
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 22px;
    letter-spacing: -0.408px;
    color: #000000;
}

.toaster_modal_header {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: -0.078px;
    color: #777777;
}