@import "../../fonts.css";
@import "../../index.css";

.container {
  padding: 24px 15px 0 10px;
}

.noResultsContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 60px;
}

.noResultsYet {
  font-weight: 600;
  font-size: 22px;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.headerText {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
}

.historyLink {
  cursor: pointer;
  color: #2043f8;
  font-weight: 600;
}

.cardsContainer {
  margin: 20px -20px;
}

.resultCard {
  background: #f3f3f3;
  border-radius: 8px;
  width: 343px;
  margin: 0 20px;
  padding: 20px 0;
}

.cardHeader {
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
}

.cardDate {
  font-size: 12px;
  align-self: flex-end;
  color: #777777;
}

.resultContainerRed {
  display: flex;
  flex-direction: column;
  text-align: center;
  background: #d23c3c;
  margin: 14px 0;
  padding: 20px;
}

.resultContainerGreen {
  display: flex;
  flex-direction: column;
  text-align: center;
  background: #78da76;
  margin: 14px 0;
  padding: 20px;
}

.resultContainerYellow {
  display: flex;
  flex-direction: column;
  text-align: center;
  background: #ff9900;
  margin: 14px 0;
  padding: 20px;
}

.resultsTextRed {
  font-size: 17px;
  color: #ffffff;
  font-weight: 600;
}

.resultsText {
  font-size: 17px;
  font-weight: 600;
}

.resultsSubText {
  font-size: 13px;
  color: #e3e3e3;
}

.resultsButton {
  font-weight: 600;
  font-size: 15px;
  text-align: center;
  padding: 10px;
  background: #ffffff;
  border-radius: 10px;
  margin-top: 12px;
  cursor: pointer;
}

.detailsContainer {
  padding: 10px 20px 0;
  display: flex;
  flex-direction: column;
}

.detailsText {
  font-weight: 600;
  font-size: 17px;
}

.horizontalLine {
  width: 90%;
}

.sharedWith {
  align-self: flex-start;
  margin: 8px 0;
  padding: 4px 12px 4px 12px;
  background: #e3e3e3;
  border-radius: 100px;
}

.resultDetailsButtonContainer {
  padding: 10px 20px 0;
  display: flex;
  flex-direction: column;
  margin-right: 0px;
}


@media (max-width: 768px) {
  .resultDetailsButtonContainer {
    margin-right: 20px;
  }
}



.resultDetailsButton {
  font-weight: 600;
  font-size: 17px;
  text-align: center;
  padding: 10px;
  background: #ff9900;
  border-radius: 10px;
  margin-top: 12px;
  cursor: pointer;
}

.noResultDetails {
  font-weight: 600;
  font-size: 17px;
  text-align: center;
  padding: 10px;
  margin-top: 12px;
}

.linkContainer {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.alertContactsLink {
  cursor: pointer;
  color: #2043f8;
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: -0.408px;
}

@media only screen and (max-width: 1200px) {
  .resultCard {
    background: #f3f3f3;
    border-radius: 8px;
    width: 280px;
    margin: 0 20px;
    padding: 20px 0;
  }
}

@media (max-width: 768px) {
  .resultCard {
    background: #f3f3f3;
    border-radius: 8px;
    width: 100vw;
    margin: 0 0px;
    padding: 20px;
  }

  .cardHeader {
    display: flex;
    justify-content: start;
    padding: 0 20px;
  }

  .headerText {
    padding-right: 10px;
  }
}