@import '../../index.css';

.splash-container {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    height: 100vh;
    align-items: center;
    justify-content: center;

    font-family: Open Sans;
}

.welcome-text {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 32px;

    text-align: center;
    letter-spacing: 0.3em;

    /* Neutral Grey */
    color: #86868B;
}

.subtitle-1 {
    font-style: normal;
    font-weight: normal;
    font-size: 26px;
    line-height: 32px;

    text-align: center;
    letter-spacing: 0.374px;

    /* Secondary */
    color: #004696;
}

.subtitle-2 {
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 32px;

    text-align: center;
    letter-spacing: 0.374px;
    width: 250px !important;

    /* Primary */
    color: #254659;
}

.button-container {
}

.btn-activate {
    background: #DB346E !important;
    border-radius: 4px !important;
    width: 300px;
}

.btn-activate-text {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 32px;

    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    letter-spacing: 0.02em;

    color: #FFFFFF;
}

.btn-signin {
    background: #FFFFFF;

    border: 2px solid #004696 !important;
    box-sizing: border-box !important;
    border-radius: 4px !important;
    width: 300px;
}

.btn-signin-text {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;

    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    letter-spacing: 0.02em;

    color: #004696;
}

.clean-link {
    text-decoration: none;
}

.skip-text {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;

    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.02em;

    color: #0A7F8A;
}
