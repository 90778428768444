@import '../../index.css';

.container {
  background: var(--dark-gray-color);
  padding: 25px 25px 0 25px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.othercontainer {
  width: 100%;
  background: var(--dark-gray);
  /* padding: 25px; */
  border: 2px solid var(--dark-gray);
  border-radius: 8px;
}

.subContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 5px;
}

.header {
  display: flex;
  justify-content: space-between;
}

.subContainer h4 {
  margin-right: 7px;
}

.cardFontBlue {
  font-weight: 500 !important;
  color: #2043F8 !important;
  cursor: pointer;
}

.subText {
  font-style: italic !important;
}

.resultsContainer {
  cursor: pointer;
}

.nextTestDateContainer {
  margin-bottom: 15px;
  cursor: pointer;
}

.superContainer {
  width: 450px;
}

.summaryContainer { 
  display: flex;
  justify-content: space-between;
  padding: 13px 25px 0px;
}

.summaryText {
  font-size: 16px ;
  font-weight:  600 ;
  line-height: 22px ;
  color: #777777 
}

.testContainer {
  display: flex;
  padding: 0px 25px 13px
}

.testBox { 
  display: flex;
  padding: 1.5px 9px;
  margin: 0px 10px;
  background: #fff;
  border-radius: 8px;
  font-weight: 700; 
  font-size: 28px
}

.testText { 
  display: flex;
  flex-direction: column;
  font-size: 15px;
  font-weight: 600;
}

.testDetailContainer {
  background-color: #fff;
  color: #000;
  padding: 18px 25px;
}

.testDetailSection {
  display: flex;
  justify-content: space-between}

.testText {
  font-weight: 600;
}

.manageText {
  color: #2043F8;
}

.detailSection {
  display: flex
}

.calendarSection {
  display: flex;
  align-items: center
}

.calendarSectionDetail {
  font-size: 12px;
  margin-left: 8px;
}

.textDetail {
  font-weight: 600;
  margin-bottom: -2px
}

.timeDetail {
  color: #777777;
  font-size: 11px
}

.locationSection {
  display: flex;
  align-items: center;
  margin-left: 16px
}

.noTestsDetail {
  padding: 17px;
}