@import '../../fonts.css';
@import '../../index.css';

.container {
  display: flex;
  padding-left: 0 !important;
  padding-right: 0 !important;
  height: 100%;
  padding-bottom: 50px;
}

.subContainer {
  margin-top: 25px;
  margin-left: 58px;
  max-width: 500px;

}

.flex1 {
  flex: 1
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.contentContainer {
  /* background: #F3F3F3; */
  border: 1px solid dashed;
  border-radius: 8px;
  padding: 25px;
  padding-top: 0;
  margin-top: 3rem;
}

.inputfile {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

.inputfile + label {
	cursor: pointer; /* "hand" cursor */
}

.inputfile:focus + label,
.inputfile + label:hover {
    background-color: red;
}

.inputfile + label {
  font-size: 1.25em;
  font-weight: 700;
  color: white;
  background-color: black;
  display: inline-block;
  margin-top: 3rem;
  padding: 0.5rem;
}

.customFileUpload {
  display: inline-block;
  cursor: pointer;
  font-weight: 600;
  background-color: #fff;
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  cursor: pointer;
  min-width: -moz-fit-content;
  min-width: fit-content;
  padding: 10px;
  border: 1px black dashed;
}

.anyVaccineAllergies {
  font-size: .90rem;
  padding-left: 1.2rem;
  margin-bottom: 0rem;
}

.screeningQuestionFooter {
  /* margin-top: -.5rem !important; */
  padding-bottom: .5rem;
}

.helper {
  margin-top: -.85rem !important;
}

.checkBoxText span {
  font-size: .9rem;
}

.questionDivider {
  margin-top: 0rem !important;
  margin-bottom: .5rem !important;
}

.appointmentConfirmation{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 1rem;
  text-align: center;
}

.apptLocation {
  margin-bottom: 0 !important;
}

.riskConfirmation {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.riskInfoGraphic {
  display: flex;
  flex-direction: row;
  padding-bottom: 1rem;
}

.cardTitle {
  text-align: center;
  font-weight: 600 !important;
}

.greenCard {
  background-color: rgb(160, 227, 160) !important;
  flex: 0 0 33%;
}

.yellowCard {
  background-color: rgb(242, 242, 121) !important;
  flex: 0 0 33%;
}

.redCard {
  background-color: rgb(242, 79, 79) !important;
  flex: 0 0 33%;
}

.greenText {
  color: rgb(160, 227, 160);
}

.yellowText {
  color: rgb(242, 242, 121);
}

.redText {
  color: rgb(242, 79, 79);
}

.checkInButton {
  background-color: lightblue !important;
}

.checkInButton span {
  font-weight: 600;
}

@media (max-width: 1024px) {
  .container {
    flex-direction: column;
  }

  .subContainer {
    margin: 50px;
  }
}

@media (max-width: 768px) {
  .subContainer {
    margin: 25px;
    width: 400px;
  }

  .flex1 {
    display: flex;
    justify-content: center;
  }

  .inputNameContainer {
    flex-direction: column;
  }

  .riskInfoGraphic {
    flex-direction: column;
  }
}
