@import '../../fonts.css';
@import '../../index.css';

.container {
  display: flex;
  padding-left: 0 !important;
  padding-right: 0 !important;
  height: 100%;
}

.container1 {
  padding-left: 60px;
  padding-right: 50px;
  max-width: 500px;
}

.flex1 {
  flex: 1
}

.listContainer {
  padding-top: 43px;
}

.dateContainer {
  display: flex;
  align-items: center;
  cursor: pointer;
}

@media (max-width: 1024px) {
  .container {
    flex-direction: column;
  }

  .container1 {
    padding: 50px;
    padding-top: 0px;
  }
}

@media (max-width: 768px) {
  .container1 {
    padding: 25px;
    padding-top: 0px;
    max-width: 400px;
    width: 100%;
  }

  .listContainer {
    display: flex;
    justify-content: center;
    padding-top: 25px;
  }

}