

.modalHeader {
    padding: 1rem;
    border-bottom: rgb(228, 225, 225) solid 1px;
}

.modalCloseButton {
    cursor: pointer;
    justify-self: flex-start;
}

.modalHeaderTitle {
    text-align: center !important;
    margin-bottom: 0;
    font-family: Arial;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 0.8px;
    color: #000000;
}

.modalItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1rem;
    border-bottom: rgb(228, 225, 225) solid 1px;
    cursor: pointer;
}

.modalItemText {
    margin-left: 1rem;
    margin-bottom: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 22px;
    display: flex;
    align-items: center;
    letter-spacing: -0.408px;
    color: #000000;
}

.bb {
    padding: 10px 4px;
    position: fixed !important;
    top: 4%;
    right: 2%;
    border: 0px !important;
    border-radius: 28px;
}

.bb2 {
    padding: 22px 15px;
    border: 0px !important;
    border-radius: 40px;
    min-width: 20vw;
}

/* @media (max-width: 992px) {
    
} */
