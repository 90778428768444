.container {
  padding: 0 1rem;
  height: 100%;
}
.tabContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.tabItem {
  padding: 0.5rem;
  margin: 0 0.5rem;
  cursor: pointer;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: -0.32px;
  color: #777777;
}
.tabItemSelected {
  padding: 0.5rem;
  margin: 0 0.5rem;
  cursor: pointer;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: -0.32px;
  color: #000000;
  border-bottom: 5px solid #ff9900;
}
.flex1 {
  height: 100%;
}
.headerButtonContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.headerText {
  font-style: normal;
  font-weight: 590;
  font-size: 13px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: right;
  letter-spacing: -0.078px;
  color: #777777;
  margin-right: 1rem;
  margin-bottom: 0;
}
.page {
  display: flex;
  flex-direction: row;
}

.pageRight {
  background: #f5f5f5;
  border: 1.5px solid #e8e8e8;
  max-width: 30%;
  min-width: 30%;
}

@media (max-width: 900px) {
  .pageRightBG {
    background-color: black;
    width: 100% !important;
  }
  .pageRight {
    height: 100vh !important;
    overflow-y: auto;
    overscroll-behavior: contain;
    position: fixed;
    z-index: 1000;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding-bottom: 30px;
    max-width: 90%;
    min-width: 90%;
  }
}

.closeButton {
  cursor: pointer;
  margin: 1rem;
}
.titleContainer {
  /* margin-top: 43px; */
  margin-left: 36px;
  /* margin-bottom: 14px; */
}

.sideContentContainer {
  margin-left: 36px;
  margin-right: 36px;
  margin-top: 29px;
}
.icon {
  width: 18px;
  margin-left: 10px;
}

.sideContentData {
  padding: 40px 0;
}

.strokeButton {
  margin-top: 20px;
}

.strokeButtonPDF {
  margin-top: 20px;
  background: #ff9900;
  border: none !important;
}

.linkContainer {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.scheduleLink {
  cursor: pointer;
  color: #2043f8;
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
}

@media (max-width: 1024px) {
  .container {
    flex-direction: column;
  }

  .sideContainer {
    border-left-style: none;
  }

  .sideContentContainer {
    margin-left: 54px;
    margin-right: 54px;
  }

  .titleContainer {
    margin-left: 54px;
  }

  .container1 {
    padding: 54px;
    padding-top: 0;
  }

  .flex1 {
    flex: 0;
  }
}
@media (max-width: 768px) {
  .sideContentContainer {
    margin-left: 25px;
    margin-right: 25px;
  }

  .titleContainer {
    margin-left: 25px;
  }

  .container1 {
    padding: 25px;
    padding-top: 0;
  }

  .flex1 {
    flex: 0;
  }
}
.headerSection {
  display: flex;
  align-content: center;
  align-items: center;
  margin: 15px 10px;
}

.tabText {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.75px;
  color: #8b8b8b;
}

.modalHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-bottom: rgb(228, 225, 225) solid 1px;
}

.modalCloseButton {
  cursor: pointer;
  justify-self: flex-start;
}

.modalHeaderTitle {
  margin-right: 20px;
  text-align: center;
  margin-bottom: 0;
  font-family: Arial;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.8px;
  color: #000000;
}

.modalItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1rem;
  border-bottom: rgb(228, 225, 225) solid 1px;
  cursor: pointer;
}

.modalItemText {
  margin-left: 1rem;
  margin-bottom: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 22px;
  display: flex;
  align-items: center;
  letter-spacing: -0.408px;
  color: #000000;
}

.bb {
  padding: 10px 4px;
  position: fixed !important;
  top: 4%;
  right: 2%;
  border: 0px !important;
  border-radius: 28px;
}

.bb2 {
  padding: 12px 5px;
  border: 0px !important;
  border-radius: 40px;
}
