.h-screen {
  height: 100vh;
  background-color: #f5f5f5;
}

@media (max-width: 768px) {
  .h-screen {
    height: auto;
    padding-bottom: 20px;
  }
}

.a-time::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

.round {
  width: 24px;
  height: 24px;
}

.side_text {
  font-style: normal;
  font-weight: 650;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: -0.32px;
  color: #000000;
}

.h-line {
  height: 70px;
  width: 0px !important;
}

@media (max-width: 768px) {
  .h-line {
    height: 40px;
  }
}

.header-text {
  font-family: "Arial";
  font-style: normal;
  font-weight: 700;
  font-size: 34px;
  line-height: 41px;
  letter-spacing: 0.374px;
  color: #000000;
}

@media (max-width: 576px) {
  .header-text {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.75px;
    color: #000000;
  }
}

@media (max-width: 768px) {
  .header-text {
    font-style: normal;
    font-size: 24px;
    line-height: 26px;
  }
}

.content-header {
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.8px;
  color: #777777;
}

@media (max-width: 576px) {
  .pos {
    position: fixed;
    z-index: 1000;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 99%;
  }

  .content-header {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.005em;
    color: #000000;
  }
}

.list-Box {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  align-items: center;
  letter-spacing: -0.32px;
  color: #000000;
  padding: 8px 20px;
  background: #fafafa;
  border: 1px solid #eeeeee;
  box-sizing: border-box;
  border-radius: 8px;
}

.begin {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 46px;
  background: linear-gradient(0deg,
      rgba(255, 153, 0, 0.8),
      rgba(255, 153, 0, 0.8)),
    #ffffff;
  border: 1.25px solid #ff9900;
  box-sizing: border-box;
  border-radius: 14px;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: -0.408px;
  color: #000000;
  cursor: pointer;
}

.back-button {
  cursor: pointer;
}

.begin:hover {
  border: 4px solid #ff9900;
}

.cancel:hover {
  border: 3px solid #000000;
}

.cancel {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 46px;
  box-sizing: border-box;
  border-radius: 14px;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: -0.408px;
  color: #000000;
  background: #ffffff;
  border: 1.25px solid #000000;
}

/* Test Site CSS */

.testsite-header {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.75px;
  color: #000000;
}

.testSiteResponse-header {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.75px;
  color: #000000;
}

.testSiteResponse-sub-header {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.24px;
  color: #000000;
}

/* Date and Time CSS */

.dnt-header {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.75px;
  color: #000000;
}

.date-text {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #000000;
}

.select-text {
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: -0.408px;
  color: #000000;
  opacity: 0.4;
}

.h-0 {
  height: 0px !important;
}

.in {
  border: none;
  outline: none;
  border-bottom: 1px #777777;
  width: 100%;
}

.dropIcon {
  right: 0;
  top: 0px;
  bottom: 0px;
  padding-right: 3px;
  display: flex;
  align-items: center;
}

.dateInput {
  border: none;
  border-bottom: 1px solid #777777;
  padding: 0.5rem 0;
  width: 100%;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.408px;
  color: #000000;
  font-weight: 600;
}

.dateInput:focus {
  outline: none;
}

.dateInput::placeholder {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.408px;
  color: #999999;
}

/* Test Result CSS */

.testResult-header {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.75px;
  color: #000000;
}

.testResult-sub-header {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.24px;
  color: #000000;
}

.selectedResult {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 10px;
  height: 55px;
  background: linear-gradient(0deg,
      rgba(255, 153, 0, 0.8),
      rgba(255, 153, 0, 0.8)),
    #ffffff;
  border-radius: 100px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: -0.32px;
  color: #000000;
}

.notSelectResult {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 10px;
  height: 55px;
  background: #e8e8e8;
  border-radius: 100px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: -0.32px;
  color: #777777;
}

/*SID CSS */

.sid-header {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.75px;
  color: #000000;
}

.sid-label {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #000000;
}

.sidInput {
  border: none;
  border-bottom: 1px solid #777777;
  padding: 0.5rem 0;
  width: 100%;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.408px;
  color: #000000;
  font-weight: 600;
  outline: none;
}

.sidInput input:focus {
  outline: none;
}

.no-sid {
  cursor: pointer;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: -0.408px;
  color: #2043f8;
}

.exclamationIcon {
  cursor: pointer;
  right: 0;
  top: 0px;
  bottom: 0px;
  padding-right: 3px;
  display: flex;
  align-items: center;
}

/* UploadFile CSS */

.UploadFile-header {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.75px;
  color: #000000;
}

.UploadFile-sub-header {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.24px;
  color: #000000;
}

.UploadFile-box {
  height: 300px;
  border-style: dotted;
  border-radius: 10px;
  border-color: lightgray !important;
}

.UploadFile-drag {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  letter-spacing: -0.32px;
  color: #777777;
}

.no-file {
  cursor: pointer;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: -0.408px;
  color: #2043f8;
}

.fileImage {
  cursor: pointer;
  width: 143px;
  height: 127px;
}

.fileText {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.078px;
  color: #000000;
}

.fileIcon {
  width: 28px;
  height: 37px;
}

.fileslist {
  top: 0;
  left: 0;
}

.replace-text {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #2043F8
}

.cutIcon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

/* FinalSubmit CSS */

.FinalSubmit-header {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.75px;
  color: #000000;
}

.FinalSubmit-main-header {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.24px;
  color: #000000;
}

.FinalSubmit-edit {
  cursor: pointer;
  right: 0;
  top: 0px;
  bottom: 0px;
  padding-right: 3px;
  display: flex;
  align-items: center;

  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  text-align: right;
  letter-spacing: -0.408px;
  color: #2043f8;
}

.FinalSubmit-edit:hover {
  text-decoration: underline;
}

/*  */

.FinalSubmit-label {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #000000;
}

.FinalSubmitInput {
  border: none;
  border-bottom: 1px solid #777777;
  padding: 0.5rem 0;
  width: 100%;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.408px;
  color: #000000;
  font-weight: 600;
  outline: none;
  padding-right: 40px;
}

.FinalSubmitInput input:focus {
  outline: none;
}

.FinalSubmitInput-UploadFile-box {
  height: 120px;
  border-style: dotted;
  border-radius: 10px;
  border-color: lightgray !important;
}

.FinalSubmitInput-fileImage {
  cursor: pointer;
  width: 100px;
  height: 90px;
}

.upload_modal {
  top: 30%;
}

.upload_modal_content {
  border-radius: 20px;
}

.circle {
  border-radius: 100%;
  background: linear-gradient(0deg,
      rgba(255, 153, 0, 0.8),
      rgba(255, 153, 0, 0.8)),
    #ffffff;
  width: 86px !important;
  height: 65px !important;
}