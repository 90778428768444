@import "../../fonts.css";
@import "../../index.css";

.container {
  display: flex;
  padding-left: 0 !important;
  padding-right: 0 !important;
  height: 100%;
  padding-bottom: 50px;
}

.subContainer {
  margin-top: 25px;
  margin-left: 58px;
  max-width: 500px;
}

.flex1 {
  flex: 1;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.contentContainer {
  border: 1px solid dashed;
  border-radius: 8px;
  padding: 25px;
  padding-top: 0;
  margin-top: 3rem;
}

.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.inputfile + label {
  cursor: pointer;
}

.inputfile:focus + label,
.inputfile + label:hover {
  background-color: red;
}

.inputfile + label {
  font-size: 1.25em;
  font-weight: 700;
  color: white;
  background-color: black;
  display: inline-block;
  margin-top: 3rem;
  padding: 0.5rem;
}

.customFileUpload {
  display: inline-block;
  cursor: pointer;
  font-weight: 600;
  background-color: #fff;
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  cursor: pointer;
  min-width: -moz-fit-content;
  min-width: fit-content;
  padding: 10px;
  border: 1px black dashed;
}

.hasInsurance {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 3rem;
  text-align: center;
}

.insuranceTitle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.nonInsuranceTitle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.addInsurancePlanLink {
  color: blue;
  cursor: pointer;
}

@media (max-width: 1024px) {
  .container {
    flex-direction: column;
  }

  .subContainer {
    margin: 50px;
  }
}

@media (max-width: 768px) {
  .subContainer {
    margin: 25px;
    width: 400px;
  }

  .flex1 {
    display: flex;
    justify-content: center;
  }

  .inputNameContainer {
    flex-direction: column;
  }
}

.auraForm {
  padding: 0;
}

.auraForm .buttonSection {
  display: flex;
  justify-content: space-between;
}
.auraForm .buttonSection .btn {
  flex: 1;
}
.auraForm .buttonSection .btn:first-of-type {
  margin-right: 1rem;
  margin-left: 1rem;
}

.auraForm .loadingWrapper {
  align-items: center;
  display: flex;
  width: 100%;
}
