@import '../../fonts.css';
@import '../../index.css';

.container {
  display: flex;
  padding-left: 0 !important;
  padding-right: 0 !important;
  height: 100%;
}

.doc_sign {
  color: #2043F8;
  cursor: pointer;
}

.doc_sign:hover {
  text-decoration: underline;
  font-weight: 500;
}

.container1 {
  padding-left: 60px;
  padding-right: 50px;
  flex: 1;
  /* max-width: 500px; */
}

.flex1 {
  flex: 1
}

.listContainer {
  padding-top: 43px;
}

.dateContainer {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.cardFontBlue {
  font-weight: 500 !important;
  color: #2043F8 !important;
  font-size: 20px !important;
  cursor: pointer;
  padding-bottom: 1rem;
}

@media (max-width: 1024px) {
  .container {
    flex-direction: column;
  }

  .container1 {
    padding: 50px;
    padding-top: 0px;
  }
}

@media (max-width: 768px) {
  .container1 {
    padding: 25px;
    padding-top: 0px;
    /* max-width: 400px; */
    width: 100%;
  }

  .listContainer {
    display: flex;
    justify-content: center;
    padding-top: 25px;
  }

}
.page {
  display: flex;
  flex-direction: row;
}
.pageRight {
  background: #F5F5F5;
  /* Greyscale/Grey 4 */
  border: 1.5px solid #E8E8E8;
  max-width: 40%;
}

.docContainer {
  padding: 1rem;
  background: #F5F5F5;
}
.pageRightTitle {
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 33px;
  letter-spacing: 0.87px;
  /* Typography/Black */
  color: #000000;
  margin-bottom: 1rem;
}
.pageRightSubTitle {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.24px;
  color: #777777;
  margin-bottom: 1rem;
}
.closeButton {
  cursor: pointer;
  margin-block: 1rem;
}
.pageRightButtonContainer {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
.smoothScroll {
  cursor: pointer;
  position: fixed;
  right: 1rem;
  bottom: 1rem;
}

.docTitle {
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 28px;
  /* identical to box height, or 127% */

  display: flex;
  align-items: center;
  letter-spacing: 0.352px;

  /* Typography/Black */

  color: #000000;
}
.docSubtitle {
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 22px;
  display: flex;
  align-items: center;
  letter-spacing: -0.408px;
  color: #777777;
}
.docEntry {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: .25rem;
  margin-block: 1rem;
  cursor: pointer;
  max-width: 100%;
  border-bottom: 1px solid #ddd;
  padding-bottom: 1rem;
}

@media (max-width: 1024px) {
  .page {
    display: block;
  }
}