.noCost-header {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.75px;
  color: #000000;
}

.noCost-text {
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: -0.408px;
  color: #000000;
}

.noCost-sub-header {
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: -0.408px;
  color: #000000;
}

.selected-button {
  height: 40px;
  background: linear-gradient(
      0deg,
      rgba(255, 153, 0, 0.8),
      rgba(255, 153, 0, 0.8)
    ),
    #ffffff;
  border: 1.5px solid #ff9900;
  box-sizing: border-box;
  border-radius: 100px;

  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: -0.32px;
  color: #000000;
  cursor: pointer;
}

.unSelected-button {
  height: 40px;
  box-sizing: border-box;
  background: #e8e8e8;
  border-radius: 100px;

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: -0.32px;
  color: #777777;
  cursor: pointer;
}

.continue-button {
  cursor: pointer;
  height: 50px;
  background: linear-gradient(
      0deg,
      rgba(255, 153, 0, 0.8),
      rgba(255, 153, 0, 0.8)
    ),
    #ffffff;
  border: 1.25px solid #ff9900;
  box-sizing: border-box;
  border-radius: 14px;

  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: -0.408px;
  color: #000000;
}
