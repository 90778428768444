@import '../../index.css';

.input {
  @apply --normalText;
  background: #FFFFFF !important;
  border: 1px solid #CCCCCC !important;
  border-radius: 10px !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-right-width: 0 !important;
  height: 36px !important;
}

.close-container {
  padding-right: 10px;
  padding-left: 10px;
  padding-top: 7px;
  padding-bottom: 7px;
  border: 1px solid #CCCCCC;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-left-width: 0;
}