@import "../../fonts.css";
@import "../../index.css";

.container {
  display: flex;
  padding-left: 0 !important;
  padding-right: 0 !important;
  height: 100%;
  padding-bottom: 50px;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.gridContainer {
  display: grid;
  grid-template-columns: 125px auto;
  grid-template-rows: auto;

  grid-gap: 25px;

  padding: 0.5rem;
  width: 100%;
}

.gridContainer img {
  width: 125px;
  height: auto;
  margin: auto;
}

.gridContainer p {
  margin: auto 0;
  font-weight: 700;
}

.gridContainer > div {
  padding: 0.5rem;
}

.gridTable > .gridRow:nth-child(even) {
  background-color: #f4fbfa;
}

.index {
  width: 50px;
  margin: auto auto;
  text-align: center;
}

.gridRow {
  display: flex;
  flex-direction: row;
}

.tableContainer {
  /* margin-top: 50px;
  margin-left: 29px;
  margin-right: 29px; */
}

.videoContainer {
  /* margin-top: 50px;
  margin-left: 29px;
  margin-right: 29px; */
}

.instructions {
  max-width: 640px;
}

.flex1 {
  flex: 1;
}

.header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.title {
  color: white;
}

.subTitle {
  color: white;
}

tr:nth-child(odd) > td {
  background: white;
  vertical-align: middle;
}

tr:nth-child(even) > td {
  background: #f4fbfa;
  vertical-align: middle;
}

.index strong {
  display: inline-block;
  width: 25px;
  border: 1px solid #000;
  text-align: center;
  background: #1b3d6e;
  color: white;
}

.ortext {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ortext span {
  font-family: inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.3799999952316284px;
  margin: 0 5px;
  color: #a3a3a3;
}

.ortext:before,
.ortext:after {
  background: #a3a3a3;
  height: 1px;
  flex: 1;
  content: "";
  padding: 0 5px;
}

.btnActivate {
  background: rgba(255, 153, 0, 0.8) !important;
  border: 1.25px solid #ff9900 !important;
  border-radius: 14px !important;
  width: 343px !important;
  color: #000 !important;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.btnConfirmation {
  background: rgba(255, 153, 0, 0.8) !important;
  border: 1.25px solid #ff9900 !important;
  border-radius: 14px !important;
  width: 343px !important;
  color: #000 !important;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.contentContainer {
  /* background: #F3F3F3; */
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 25px;
  padding-top: 0;
  margin-top: 10px;
}

.normal-font,
.thin-normal-font {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: -0.408px;
  color: #000000;
  margin: 0;
}

.inputNameContainer {
  display: flex;
  flex-direction: column;
  border: 1px solid #d8d8d8;
  border-radius: 8px;
  min-height: 70px;
}

.MuiAlert-filledError {
  margin-bottom: 4rem;
}

.subContainer {
  margin: 50px;
  /* max-width: 640px; */
}

.instructionsContainer {
  margin: 50px;
  max-width: 1350px;
}

@media (max-width: 1024px) {
  .container {
    flex-direction: column;
  }

  .instructionsContainer {
    margin: 2rem;
  }
}

@media (max-width: 768px) {
  /* .subContainer {
    margin: 0;
    width: 100%;
  } */

  .gridContainer {
    display: grid;
    grid-template-rows: 100px 1fr;
    grid-template-columns: auto;
    grid-gap: 5px;
    padding: 0.5rem;
  }

  .gridContainer img {
    width: 150px;
    height: auto;
    margin: auto;
    max-height: 121px;
  }

  .gridContainer p {
    font-size: 14px;
  }

  .subContainer {
  }

  .index {
    margin: 10px 0;
  }

  .content {
    padding: 24px;
  }

  .flex1 {
    display: flex;
    /* justify-content: center; */
    flex-direction: column;
  }

  .instructionsContainer {
    margin: 0.5rem;
    padding-bottom: 1rem;
  }
}

@media (max-width: 376px) {
  .subContainer {
    margin: 0;
    width: 100%;
  }
  .shopLogo {
    width: 250px;
    height: "auto";
  }
}

.wizardButtons {
  text-align: center;
}

.formLine {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  max-width: 1000px;
  margin: 0 auto;
}

.formInput {
  margin-left: 10px;
  margin-right: 10px;
}

.headerText {
  font-family: SF Pro Display;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */

  letter-spacing: 0.75px;
}
.headerText2 {
  font-family: SF Pro Text;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 160%;
}
.dashedButton2 {
  margin: auto;
}
.btnActivate2 {
  margin: auto !important;
}
.btnActivate3 {
  margin-top: 2rem;
}

.tubeLabelContainer {
  background: #edf5fa;
  border: 1px solid #deecf5;
  box-sizing: border-box;
  border-radius: 0px;
  padding-left: 1rem;
  padding-bottom: 1rem;
}
.tubeLabelInfoContainer {
  border-radius: 50px 0px 0px 50px;
  background: rgba(255, 153, 0, 0.8);
  padding: 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.tubeLabelText {
  font-size: 13px;
  margin-bottom: 0;
  margin-left: 1rem;
}

.testKitsGridContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 0.5rem;
  padding: 0.5rem;
}

.testKitCard {
}

.testKitCardSelected {
  border-color: #ff9900 !important;
  border-width: 1px !important;
}

.testKitCardBodyLeftText {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
}

.testKitCardHeader {
  background: #f3f3f3;
  border-radius: 8px 8px 0px 0px;
  padding: 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.testKitCardBody {
  padding: 0.75rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.testKitContinueBtn {
  max-width: 50%;
  margin-top: 3rem;
}

.selectTestKitText {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  width: 100%;
}

.testKitCardBodyRightText {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  padding: 0.75rem;
  min-width: 100px;
  text-align: center;
  border-radius: 100px;
}

.noTestKitsContatiner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}

.testKitCardTitle {
  font-family: "SF Pro Display", "Helvetica", "Arial", sans-serif;
  font-style: normal;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: -0.408px;
  color: #000000;
  margin-bottom: 0;
}

.testKitCardSubtitle {
  font-family: "SF Pro Display", "Helvetica", "Arial", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.078px;
  color: #777777;
  margin-bottom: 0;
}
.testKitCardSubtitleBlue {
  font-family: "SF Pro Display", "Helvetica", "Arial", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.078px;
  color: #405add;
  margin-bottom: 0;
}
.testKitCardSubtitle2 {
  font-family: "SF Pro Display", "Helvetica", "Arial", sans-serif;
  font-style: italic;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.078px;
  color: #777777;
  margin-bottom: 0;
}
