@import "../../index.css";
@import "../../fonts.css";

.maincontainer {
  display: flex;
  padding-left: 0 !important;
  padding-right: 0 !important;
  width: 100%;
  max-width: 1012px;
}

.flex1 {
  flex: 1;
}

.cancelButton {
  margin-inline: 1rem;
}

.modal {
  border-radius: 8px;
}

.flex-parent div {
  margin-right: 1rem !important;
}

.header {
  margin-left: 61px;
  line-height: 35px;
}

.maincontainer h4 {
  line-height: 1.3rem;
}

.sideContainer {
  padding: 0px 20px 0 30px;
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: repeat(4, 210px);
  row-gap: 26px;
  column-gap: 32px;
}

.certificationButton {
  background-color: #c04119;
  height: 210px;
  width: 210px;
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 130%;
  letter-spacing: -0.32px;
  color: #ffffff;
  margin-right: 1.5rem;
}

.certificationButton:hover {
  opacity: 0.5;
}

.cdcButton {
  background-color: #1860a9;
  background-image: url("../../assets/image/cdc.png");
  flex: 1;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-size: 100% 100%;
  width: 210px;
  height: 210px;
}

.cdcButton:hover {
  opacity: 0.5;
}

.cdcButton > p {
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 130%;
  text-align: center;
  letter-spacing: -0.32px;
  color: #ffffff;
  margin: 0;
}

.cdcButton > p:last-of-type {
  font-weight: normal;
  font-size: 18px;
}

.divider {
  width: 25px;
}

.bottomText {
  max-width: 1012px;
  margin-top: 70px;
}

.superContainer {
  grid-column: 1 / span 2;
}

.container {
  background: var(--dark-gray-color);
  padding: 25px 25px 0 25px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.othercontainer {
  width: 100%;
  background: var(--dark-gray);
  border: 2px solid var(--dark-gray);
  border-radius: 8px;
}

.cardSubContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 5px;
}

.cardHeader {
  display: flex;
  justify-content: space-between;
}

.cardSubContainer h4 {
  margin-right: 7px;
}

.cardFontBlue {
  font-weight: 500 !important;
  color: #2043f8 !important;
  cursor: pointer;
}

.subText {
  font-style: italic !important;
}

.resultsContainer {
  cursor: pointer;
}

.nextTestDateContainer {
  margin-bottom: 15px;
  cursor: pointer;
}

.summaryContainer {
  display: flex;
  justify-content: space-between;
  padding: 13px 25px 0px;
}

.summaryText {
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  color: #777777;
}

.testContainer {
  display: flex;
  padding: 0px 25px 13px;
}

.testBox {
  display: flex;
  padding: 1.5px 9px;
  margin: 0px 10px;
  background: #fff;
  border-radius: 8px;
  font-weight: 700;
  font-size: 28px;
}

.testText {
  display: flex;
  flex-direction: column;
  font-size: 15px;
  font-weight: 600;
}

.testDetailContainer {
  background-color: #fff;
  color: #000;
  padding: 18px 25px;
}

.testDetailSection {
  display: flex;
  justify-content: space-between;
}

.manageText {
  color: #2043f8;
}

.detailSection {
  display: flex;
}

.calendarSection {
  display: flex;
  align-items: center;
}

.calendarSectionDetail {
  font-size: 12px;
  margin-left: 8px;
}

.textDetail {
  font-weight: 600;
  margin-bottom: -2px;
}

.timeDetail {
  color: #777777;
  font-size: 11px;
}

.locationSection {
  display: flex;
  align-items: center;
  margin-left: 16px;
}

.noTestsDetail {
  padding: 17px;
}

@media (max-width: 1024px) {
  .header {
    margin-left: 53px;
  }
  .maincontainer {
    flex-direction: column;
  }

  .sideContainer {
    padding: 0px;
    margin: 30px;
    margin-top: 0px;
    display: grid;
    grid-template-rows: auto 1fr;
    grid-template-columns: repeat(3, 1fr);
    gap: 28px;
  }

  .superContainer {
    max-width: 410px;
  }

  .cdcButton {
    background-color: #1860a9;
    background-image: url("../../assets/image/cdc.png");
    flex: 1;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-size: 100% 100%;
    width: 180px;
    height: 200px;
  }

  .certificationButton {
    background-color: #c04119;
    height: 200px;
    width: 180px;
    flex: 1;
    justify-content: center;
    align-items: center;
    display: flex;
    border-radius: 8px;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 130%;
    letter-spacing: -0.32px;
    color: #ffffff;
    margin-right: 1.5rem;
  }

  .bottomText {
    margin: 50px;
  }
}

@media (max-width: 768px) {
  .header {
    margin-left: 33px;
  }

  .sideContainer {
    padding: 0px;
    margin: 30px;
    margin-top: 26px;
    display: grid;
    grid-template-rows: auto 1fr;
    grid-template-columns: repeat(3, 1fr);
    gap: 28px;
  }

  .bottomText {
    margin: 30px;
  }
}

@media (max-width: 600px) {
  .sideContainer {
    padding: 0px;
    margin: 30px;
    margin-top: 26px;
    display: grid;
    grid-template-rows: auto 1fr;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
    max-width: 100%;
  }
  .superContainer {
    max-width: 100%;
  }

  .cdcButton {
    width: 100%;
  }
  .certificationButton {
    width: 100%;
  }
}

/* @media (max-width: 500px) {
  .superContainer {
    margin-right: 30px;
  }
} */

@media (max-width: 450px) {
  .sideContainer {
    padding: 0px;
    margin: 30px;
    margin-top: 26px;
    display: grid;
    max-width: 380px;
    grid-template-rows: auto;
    grid-template-columns: auto;
    gap: 12px;
  }

  .cardContainer {
    grid-column: 1 / span 2;
  }
}
