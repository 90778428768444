.myDoc {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    line-height: 41px;
    letter-spacing: 0.374px;
    color: #000000;
}

.myDoc-header {
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 33px;
    letter-spacing: 0.87px;
    color: #000000;
}

.back-button {
    cursor: pointer;
}

.markdown ul{
    padding-left: 2.5rem;
    list-style-type:disc;
}

.myDoc-container {
    width: 85%;
    margin-top: -100px;
}

.smoothScrollBtn {
    cursor: pointer;
    position: fixed;
    right: 4rem;
    bottom: 3rem;
}

@media (max-width: 768px) {
    .myDoc-container{
        width: 100%;
        margin-top: 0px;
    }
    .smoothScrollBtn {
        right: 1rem;
        bottom: 1rem;
    }
}



@media (max-width: 500px) {
    .myDoc {
        font-weight: 600;
        font-size: 24px;
        line-height: 31px;
    }
    .myDoc-header {
        font-weight: 600;
        font-size: 22px;
        line-height: 24px;
    }
    
} 

.myDoc-modal {
    top: 30%;
    border-radius: 3rem;
}
  
.myDoc-modal_content {
    border-radius: 3rem;
}
  
.myDoc-circle {
    border-radius: 100%;
    background: linear-gradient(
        0deg,
        rgba(255, 153, 0, 0.8),
        rgba(255, 153, 0, 0.8)
        ),
        #ffffff;
    width: 86px !important;
    height: 65px !important;
}

.myDoc-modal-header {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.75px;
    color: #000000;
}

.myDoc-drag {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    letter-spacing: -0.32px;
    color: #777777;
}


.myDoc-cancel:hover {
    border: 3px solid #000000;
}
  
.myDoc-cancel {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 46px;
    box-sizing: border-box;
    border-radius: 14px;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 22px;
    letter-spacing: -0.408px;
    color: #000000;
    background: #ffffff;
    border: 1.25px solid #000000;
}

