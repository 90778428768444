
  :root {

    /* ================ Colors ================ */

    --light-gray-color: #CCCCCC;
    --dark-gray-color: #F3F3F3;
    --dark-gray: #D9D9D9;
    --text-gray-color: #777777;
    --white: #FFFFFF;
    --gray-color: #F9F9F9;
    --primary-color: #FF9900;
    --success-color: #78DA76;
    --blue-color: #2043F8;

      /* ================ Custom media queries ================ */

  @custom-media --viewportSmaller (min-width: 320px);
  @custom-media --viewportSmall (min-width: 550px);
  @custom-media --viewportMedium (min-width: 768px);
  @custom-media --viewportLarge (min-width: 1024px);
  @custom-media --viewportLargeWithPaddings (min-width: 1128px);
  @custom-media --viewportLarger (min-width: 1400px);
  @custom-media --viewportXLarge (min-width: 1921px);
  }
  
  body {
    margin: 0;
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  /* ================ Custom media queries ================ */

  @custom-media --viewportSmaller (min-width: 320px);
  @custom-media --viewportSmall (min-width: 550px);
  @custom-media --viewportMedium (min-width: 768px);
  @custom-media --viewportLarge (min-width: 1024px);
  @custom-media --viewportLargeWithPaddings (min-width: 1128px);
  @custom-media --viewportLarger (min-width: 1400px);
  @custom-media --viewportXLarge (min-width: 1921px);


  .CERTIFIED {
    padding:15px;
    background-color: green;
  }

  .DENIED {
    padding:15px;
    background-color: red;
  }

  .DENIED p {
    white-space: pre;
  }

  /* Print. */
@media print {
  /* cssclsNoPrint class. */
  .cssclsNoPrint {display:none}
  .react-easy-print-print{ width: 100% }
}

@-moz-document url-prefix() {
  @media print {
    .react-easy-print-print{ position: absolute; left: 0; right: 0; }
  }
}

/* Screen. */
@media screen {
  /* cssclsNoScreen class. */
  .cssclsNoScreen {display:none}
}


