@import "../../fonts.css";
@import "../../index.css";

.container {
  display: flex;
  padding-left: 2.2rem !important;
  padding-right: 0 !important;
  height: 100%;
  padding-bottom: 50px;
  max-width: 900px;
}

.subContainer {
  margin-top: 50px;
  margin-left: 0px;
  width: 100%;
}
.formik {
  padding: 1rem;
}
.form {
  padding: 1rem;
}

.flex1 {
  flex: 1;
}

.contentContainer {
  background: #f3f3f3;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 25px;
  padding-top: 0;
  margin-top: 10px;
}

.insuranceCardContainer {
  display: flex;
  padding-top: 0;
  margin-top: 10px;
}

.insuranceCard {
  max-width: 90%;
  max-height: 90%;
}

.policyHolderText {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.078px;
  color: #777777;
}
.policyNameText {
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  display: flex;
  align-items: center;
  letter-spacing: -0.408px;
  color: #000000;
  margin-bottom: 0;
}

.policyCard {
  border: 1px solid #f3f3f3;
  border-radius: 8px;
  margin-block: 1rem;
}
.policyCardTop {
  background: #f3f3f3;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}
.policyCardBottom {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 1rem;
}
.policyCardInfo {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  position: relative;
}

.insuranceChip {
  margin-right: 1rem;
  padding: 0.3rem;
  background-color: #ffe794;
  border-radius: 100px;
  flex-direction: row;
  align-items: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-block: 0.2rem;
}
.insuranceChipText {
  margin-bottom: 0;
  margin-inline: 0.2rem;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: -0.32px;
  color: #000000;
}

.addInsuranceContainer {
  background: #f3f3f3;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1rem;
  width: 75%;
}
.addInsuranceContainerRight {
  flex-direction: column;
}
.addInsuranceText {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.24px;
  color: #777777;
}
.addInsuranceButton {
  border: 1.5px dashed #d9d9d9 !important;
  box-sizing: border-box;
  border-radius: 14px !important;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.inputItem {
  flex: 1;
  margin-right: 1rem;
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.formContainer {
  width: 100%;
}
.editButton {
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 99;
  cursor: pointer;
}
.saveButtonDisabled {
  background: linear-gradient(
      0deg,
      rgba(255, 153, 0, 0.8),
      rgba(255, 153, 0, 0.8)
    ),
    #ffffff;
  opacity: 0.4;
  border: 1.25px solid #ff9900;
  box-sizing: border-box;
  border-radius: 14px;
  width: 70%;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  padding-block: 0.5rem;
  cursor: initial;
  margin-inline: 2rem;
}

.saveButtonDisabledWide {
  background: linear-gradient(
      0deg,
      rgba(255, 153, 0, 0.8),
      rgba(255, 153, 0, 0.8)
    ),
    #ffffff;
  opacity: 0.4;
  border: 1.25px solid #ff9900;
  box-sizing: border-box;
  border-radius: 14px;
  width: 100%;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  padding-block: 0.5rem;
  cursor: initial;
  margin-right: 2rem;
}

.saveButton {
  background: linear-gradient(
      0deg,
      rgba(255, 153, 0, 0.8),
      rgba(255, 153, 0, 0.8)
    ),
    #ffffff;
  border: 1.25px solid #ff9900;
  box-sizing: border-box;
  border-radius: 14px;
  width: 100%;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  padding-block: 0.5rem;
  margin-right: 2rem;
}
.uploadPhotoButton {
  width: 100%;
}
.imagePreview {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #f3f3f3;
  border-radius: 8px;
  width: 100%;
  margin-bottom: 1rem;
}
.replaceButton {
  margin-left: 1rem;
  cursor: pointer;
}
.header {
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  max-width: 40%;
  margin-block: 2rem;
  margin-bottom: 5rem;
}
.headerText {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.24px;
  color: #777777;
  margin-bottom: 4px;
}
.headerRight {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin-left: 1rem;
}
.titleText {
  font-family: Arial;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 20px;
  letter-spacing: -0.24px;
  color: #000000;
}
.policyItem {
  padding-bottom: 0.5rem;
}
.policyItemInner {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.policyItemLeft {
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
  padding-bottom: 0.5rem;
}
.carrierName {
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 22px;
  margin-bottom: 0.2rem;
}
.policyHolder {
  display: flex;
  margin-bottom: 0;
  font-weight: 600;
  color: gray;
}

.editRelation {
  margin-left: 1rem;
  cursor: pointer;
  color: #2043f8;
}

.relationWarningContainer {
  display: flex;
}

.relationWarningText {
  margin-top: 16px;
  padding: 4px 12px;
  color: #000000;
  font-weight: 400;
  font-size: 16px;
  margin-right: 1rem;
  background: #ffe794;
  border-radius: 100px;
}

.relationshipText {
  margin: 8px 0;
}

.relationshipValuescontainer {
  display: flex;
  flex-direction: row;
  padding-bottom: 1rem;
}

.relationshipValues {
  width: 150px;
  height: 31px;
  text-align: center;
  margin-right: 20px;
  padding-top: 4px;
  border-radius: 100px;
  background-color: #e3e3e3;
  cursor: pointer;
}

.selfDisabledRelation {
  opacity: 0.4;
  cursor: default;
  width: 150px;
  height: 31px;
  text-align: center;
  margin-right: 20px;
  padding-top: 4px;
  border-radius: 100px;
  background: #e3e3e3;
}

.selectedRelationshipValue {
  width: 150px;
  height: 31px;
  text-align: center;
  margin-right: 20px;
  padding-top: 4px;
  border-radius: 100px;
  background: linear-gradient(
      0deg,
      rgba(255, 153, 0, 0.8),
      rgba(255, 153, 0, 0.8)
    ),
    #ffffff;
  cursor: pointer;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  position: fixed;
  bottom: 0;
  width: 900px;
  padding-bottom: 2rem;
  background: #ffffff;
}

.backModalContainer {
  display: flex;
  flex-direction: row;
  padding: 2rem 1rem 0 1rem;
  align-content: center;
  text-align: center;
}

.backModalIcon {
  height: 60%;
  margin: 1.5rem 1rem 2rem 1rem;
}

.modalTextContainer {
  display: flex;
  flex-direction: column;
}

.modalHeaderTitle {
  text-align: left;
  margin-left: 10%;
  font-weight: 600;
  line-height: 22px;
  font-size: 18px;
}

.modalBodyText {
  text-align: left;
  margin-left: 10%;
  margin-right: 10%;
}

.exitModalButtonContainer {
  margin: -1rem 2rem 2rem 2rem;
  display: flex;
  justify-content: space-between;
}

.stayModalButton {
  width: 50%;
  margin-right: 1rem;
}

.exitModalButton {
  border-color: #fc67b7 !important;
}

@media (max-width: 1024px) {
  .container {
    display: flex;
    padding-left: 2rem !important;
    padding-right: 2rem !important;
    height: 100%;
    padding-bottom: 50px;
  }

  .policyHolder {
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
    font-weight: 600;
    color: gray;
  }

  .insuranceCard {
    max-width: 100%;
    max-height: 100%;
  }

  .buttonContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    position: fixed;
    bottom: 0;
    width: 100%;
    padding-bottom: 1rem;
    padding-right: 24rem;
    background: #ffffff;
  }

  .saveButtonDisabled {
    background: linear-gradient(
        0deg,
        rgba(255, 153, 0, 0.8),
        rgba(255, 153, 0, 0.8)
      ),
      #ffffff;
    opacity: 0.4;
    border: 1.25px solid #ff9900;
    box-sizing: border-box;
    border-radius: 14px;
    width: 100%;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 22px;
    padding-block: 0.5rem;
    cursor: initial;
    margin-inline: 2rem;
    margin-top: 1rem;
  }
}

@media (max-width: 768px) {
  .header {
    border: 1px solid #d9d9d9;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    max-width: 60%;
    margin-block: 2rem;
    margin-bottom: 50px;
  }

  .policyItemInner {
    display: flex;
    flex-direction: column;
  }

  .insuranceCard {
    max-width: 100%;
    max-height: 100%;
  }

  .editRelation {
    margin-left: 0;
    cursor: pointer;
    color: #2043f8;
  }

  .relationshipValuescontainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-bottom: 1rem;
  }

  .relationshipValues {
    width: 120px;
    height: 31px;
    text-align: center;
    margin-right: 20px;
    padding-top: 4px;
    border-radius: 100px;
    background-color: #e3e3e3;
    cursor: pointer;
    margin-top: 12px;
  }

  .selfDisabledRelation {
    opacity: 0.4;
    cursor: default;
    width: 120px;
    height: 31px;
    text-align: center;
    margin-right: 20px;
    padding-top: 4px;
    border-radius: 100px;
    background: #e3e3e3;
    margin-top: 12px;
  }

  .selectedRelationshipValue {
    width: 120px;
    height: 31px;
    text-align: center;
    margin-right: 20px;
    padding-top: 4px;
    border-radius: 100px;
    background: linear-gradient(
        0deg,
        rgba(255, 153, 0, 0.8),
        rgba(255, 153, 0, 0.8)
      ),
      #ffffff;
    cursor: pointer;
    margin-top: 12px;
  }

  .editLableSpan {
    display: flex;
    flex-direction: column;
  }

  .buttonContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    position: fixed;
    bottom: 0;
    width: 100%;
    padding-bottom: 1rem;
    padding-right: 4rem;
    background: #ffffff;
  }
}
