.header_text {
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 41px;
    text-align: center;
    letter-spacing: 0.374px;
    color: #000000;
}

.line {
    height: 10px;
    background-color: #FF9900;
    width: 50%;
    border-radius: 8px;
}

.content_header {
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 33px;
    letter-spacing: 0.87px;
    color: #000000;
    
}
.content_text {
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 22px;
    letter-spacing: -0.408px;
    color: #000000;
}

.content_box {
    /* width: 690px; */
    /* height: 385px; */
    background: rgba(237, 245, 250, 0.1);
    border: 1px solid #DEECF5;
    box-sizing: border-box;
    border-radius: 8px;
}
.content_box_header {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: #1E3F76;
}
.content_box_des {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.078px;
    color: #595959;
}

.ScanCTA {
   cursor: pointer;
}

@media (max-width: 768px) {
    .ScanCTA {
        width: 500px !important;
        height: 45px !important;
    }
}
@media (max-width: 576px) {
    .ScanCTA {
        width: 330px !important;
        height: 33px !important;
    }

    .header_text{
        font-weight: 500;
        font-size: 24px;
    }
    .content_header{
        font-weight: 500;
        font-size: 24px;
    }
}


.Continue_Button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 46px;
    background: linear-gradient(
    0deg,
    rgba(255, 153, 0, 0.8),
    rgba(255, 153, 0, 0.8)
    ),
    #ffffff;
    border: 1.25px solid #ff9900;
    box-sizing: border-box;
    border-radius: 14px;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 22px;
    letter-spacing: -0.408px;
    color: #000000;
    cursor: pointer;
}
  

.Continue_Button:hover {
border: 4px solid #ff9900;
}

.up_Button {
    border: lightgray;
    border-width: 1.9px;
    border-style: dashed;
    cursor: pointer;
    border-radius: 12px;
    height: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 22px;
    text-align: center;
    letter-spacing: -0.408px;
    color: #000000;
  }
