@import "../../index.css";

.login-card {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin-left: auto;
  margin-right: auto;
  width: 360px;
  max-width: 3360px;
}

.login_form {
  width: 80%;
  /* margin: 0% 10%; */
  margin-left: 15%;
}

@media (max-width: 1420px) {
  .login_form {
    margin-left: 5%;
  }
}

@media (max-width: 768px) {
  .login_form {
    margin: 0% 2%;
    width: 96%;
  }
}

.landing_note_header {
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.8px;
  color: #ffffff;
  /* color: black; */
  margin-bottom: 40px;
}

.landing_note_description {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 36px;
  text-align: center;
  letter-spacing: 0.005em;
  color: #f5f5f5;
  /* color: black; */
  margin-bottom: 40px;
}

.login_header {
  font-weight: 700;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.2em;
  color: #777777;
}

.landing_note_container {
  top: 530px;
  right: 10%;
}

@media (max-width: 860px) {
  .landing_note_container {
    top: 300px;
    right: 0%;
  }
}

@media (max-width: 576px) {
  .landing_note_container {
    top: 0%;
    right: 5%;
    left: 5%;
  }
}

.login_form_welcome_text {
  /* font-family: SF Pro Display; */
  font-size: 34px;
  font-style: normal;
  font-weight: 700;
  line-height: 41px;
  letter-spacing: 0.37400001287460327px;
  text-align: left;
}

.login_form_welcome_text_des {
  /* font-family: SF Pro Display; */
  color: #777777;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.40799999237060547px;
  text-align: left;
  margin-bottom: 43px;
}

.btn-signin_ {
  outline: none;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  width: 100%;
  background: linear-gradient(
      0deg,
      rgba(255, 153, 0, 0.8),
      rgba(255, 153, 0, 0.8)
    ),
    #ffffff;
  border: 1.25px solid #ff9900;
  box-sizing: border-box;
  border-radius: 14px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px;
  margin-top: -5px;
}
.btn-signin_:focus {
  border: 2px solid #ff9900;
  outline: none;
}

.divider {
  width: 47%;
}

.account_ {
  /* font-family: SF Pro Text; */
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.078px;
  color: #8c8c8c;
}

.form-login .welcome-text {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 31px;
  text-align: center;
  letter-spacing: 0.603807px;
  color: #000000;
}

.form-login .logo {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.form-login .btn-login {
  background: rgba(255, 153, 0, 0.8);
  border: 1.25px solid #ff9900;
  border-radius: 14px;
  width: 327px;
  color: #000;
}

.form-login .btn-signup {
  border-radius: 14px;
  width: 327px;
  color: #000;
}

.medium-sub-font-blue {
  cursor: pointer;
}

.googlebutton {
  cursor: pointer;
  background: #ffffff;
  border: 1.25px solid #777777;
  box-sizing: border-box;
  border: 1.25px solid #777777 !important;
  border-radius: 14px !important;
  width: 100% !important;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  color: #000 !important;
  padding: 4px !important;
  height: 50px !important;
  box-shadow: none !important;
  outline: none !important;
  /* margin-bottom: 15px; */
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: -0.408px;
}

.__text {
  font-style: normal;
  font-weight: 600 !important;
  font-size: 17px !important;
  line-height: 22px !important;
  letter-spacing: -0.408px !important;
  color: #000 !important;
}

.a__text {
  /* font-family: SF Pro Text; */
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: -0.32px;
  color: #2043f8;
}

.register_btn {
  cursor: pointer;
  background: #ffffff;
  border: 1.25px solid #777777;
  box-sizing: border-box;
  border: 1.25px solid #777777 !important;
  border-radius: 14px !important;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  color: #000 !important;
  padding: 4px !important;
  height: 46px !important;
  box-shadow: none !important;
  outline: none !important;
  width: 35%;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: -0.408px;
  margin-bottom: 20px;
}

.gbutton button,
.gbutton button:active,
.gbutton button:hover,
.gbutton button:focus {
  border: 1.25px solid blue !important;
  border-radius: 14px !important;
  width: 326px !important;
  color: #000 !important;
  padding-left: 14px !important;
  box-shadow: none !important;
  outline: none !important;
}

.gbutton button div {
  padding: 0 !important;
}

.tab-pane {
  margin-top: 1rem;
}
