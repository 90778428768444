@import '../../index.css';

.login-card{
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    margin-left: auto;
    margin-right: auto;
    width: 360px;
    max-width: 360px;
}

.form-login .welcome-text{
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 31px;
    text-align: center;
    letter-spacing: 0.603807px;
    color: #000000;
}

.form-login .logo{
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.form-login .btn-login {
    background: rgba(255, 153, 0, 0.8);
    border: 1.25px solid #FF9900;
    border-radius: 14px;
    width: 326px;
    color: #000;
}

.form-login .btn-signup {
    border-radius: 14px;
    width: 326px;
    color: #000;
}

.medium-sub-font-blue {
    cursor: pointer;
}