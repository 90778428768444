@import '../../index.css';
@import '../../fonts.css';

.testing-container {
  display: flex;
  padding-left: 0 !important;
  padding-right: 0 !important;
  height: 100%;
}

.flex1 {
  flex: 1;
}

.list-container {
  padding-left: 58px;
  padding-right: 58px;
  padding-top: 43px;
}

.searchText {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.75px;
  color: #000000;
}

.search-text-container {
  margin-top: 30px;
  margin-bottom: 40px;
}

.hippa-container {
  height: 400px;
  overflow: scroll;
}

.testing-map-container {
  max-height: 900px;
  height: 100%;
}

@media (max-width: 1024px) {
  .testing-container {
    flex-direction: column;
  }

  .testing-container .flex1:first-of-type {
    flex: 0;
  }
}

@media (max-width: 768px) {
  .list-container {
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 25px;
  }
}