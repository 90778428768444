@import "../../fonts.css";

.container {
  /* display: flex; */
  padding-right: 1rem !important;
  padding-top: 2rem;
  height: 100%;
}
.headerText {
  padding-left: 2rem;
}
.qrCode {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 50%;
}

.qrCodeContainer {
  margin-top: 8%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  margin-inline: 30%;
  border: 1.13609px solid #a3a3a3;
  box-sizing: border-box;
  border-radius: 9.08876px;
  padding-block: 2rem;
}

.instructionsText {
  font-family: Arial;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.0886154px;
  color: #777777;
  margin-bottom: 1rem;
}

.qrFooter {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.footerText {
  margin-bottom: 0;
  font-family: Arial;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 23px;
  letter-spacing: -0.272663px;
  color: #777777;
  padding-left: 1rem;
  border-left: 1.13609px solid #a3a3a3;
}

.auraLogo {
  margin-right: 1rem;
}

.breadCrumbs {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  letter-spacing: 0.75px;

  /* Typography / Placeholder Text */

  color: #8b8b8b;
  margin-left: 2rem;
}

.orange {
  color: #ff9900;
  display: inline;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  letter-spacing: 0.75px;

  /* Typography / Placeholder Text */
}
