@import '../../fonts.css';
@import '../../index.css';

.container {
  display: flex;
  padding-left: 0 !important;
  padding-right: 0 !important;
  height: 100%;
}

.container1 {
  padding-left: 60px;
  padding-right: 50px;
  width: 100%;
  max-width: 500px;
  position: relative;
}

.flex1 {
  flex: 1
}

.listContainer {
  padding-top: 43px;
}

.editLocation {
  cursor: pointer;
}

.sticky {
  position: sticky;
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
  bottom: 0;
  padding-bottom: 1px;
  background-color: #ffffff;
}

@media (max-width: 1024px) {
  .container {
    flex-direction: column;
  }

  .container1 {
    padding: 50px;
    padding-top: 0px;
  }
}

@media (max-width: 768px) {
  .container1 {
    padding: 25px;
    padding-top: 0px;
  }

  .listContainer {
    display: flex;
    justify-content: center;
    padding-top: 25px;
  }
}