@import '../../fonts.css';
@import '../../index.css';


.container {
  display: flex;
  padding-left: 0 !important;
  padding-right: 0 !important;
  height: 100%;
  padding-bottom: 50px;
}

.cdcContainer {
  max-width: 550px;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0,0,0,.125);
  border-radius: .25rem;
  padding: 0.8rem;
  margin-top: 1.5rem;
  margin-left: 1.5rem;
}

.cdcRowContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.cdcInfoContainer{
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  justify-content: space-between;
}


.cdColumnContainer{
  display:flex;
  flex-direction: column;
}
.cdcFullContainer{
  margin: 10px 20px;
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 0;
  flex-direction: row;
  
}
.cdcTitle{
  font-family: 'Myriad Pro Regular';
  font-weight: 700;
  font-size: 24px;
  margin-bottom: 1px;
}

.cdcInstructTest{
  font-family: 'Myriad Pro Regular';
  margin-bottom: 8px;
  font-size: 12px;
}


.cdc {
  width: 64px;
  height: 64px;
  margin-left: 8px;
  margin-top: 8px;
}

.dhs {
  width: 72px;
  height: 72px;
  margin-left: 8px;
}

.cdcFields{
  font-family: 'Myriad Pro Regular';
  font-size: 13px;
}

.cdcFields2{
  font-family: 'Myriad Pro Regular';
  font-weight: 600;
  font-size: 13px;
}

.cdcTableHeader{
  display: flex;
  flex-direction: row;
  width: 100%;
  border-top: 3px solid black;
  border-bottom: 3px solid black;
}

.cdcTableCell{
  padding-top: 5px;
  padding-bottom: 5px;
  justify-content: center;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.cdcTableRow{
  display: flex;
  flex-direction: row;
  width: 100%;
  border-bottom: 1px solid black;
}

.cdcHeaderText{
  font-family: 'Myriad Pro Semibold';
  font-size: 14px;
}

.cdcInfoText{
  font-family: 'Myriad Pro Regular';
  font-weight: 500;
  font-size: 14px;
}

.cdcCellText{
  font-family: 'Myriad Pro Regular';
  font-size: 12px;
}

.vaxColumn{
  flex: 1 1 0;
  border-right: 2px solid black;
}

.productColumn{
  flex: 3 1 0;
  border-right: 2px solid black;
  padding-left: 5px;
  text-align: left;

  
}

.dateColumn{
  flex: 1 1 0;
  flex-wrap: wrap;
  border-right: 2px solid black;

  text-align: center;
}


.siteColumn{
  flex: 2 1 0;
  flex-wrap: wrap;
  padding-left: 5px;
  text-align: left;
}

.fechaColumn{
  flex: 1 1 0;
  flex-wrap: wrap;
  text-align: center;
}


.cdcFieldsItalic{
  font-family: 'Myriad Pro Italic';
  font-size: 13px;
  font-style: italic;
}

.darkline {
  background-color: black;
  height: 0.5px;
  margin: 0 0 5px 0;
  width: 100%;
}

.rulerline {
  background-color: black;
  opacity: 0.4;
  height: 0.2px;
  margin: 2px 0 1px 0;
  width: 100%;
}

.dashedline {
  opacity: 0.4;
  height: 0.2px;
  border: 0.2px dashed black;
  margin: 2px 0 2px 0;
  width: 99%;
}

/* .dashedTop{
  border-top: 0.2px dashed black;
} */

.timeRemaining {
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 1.2962932586669922px;
  text-align: center;
}

.timerContainer {
  justify-content: center;
  
  display: flex;
}

.timeText{
  font-weight: bold;
  font-size: 70px;
  align-items: center;
  text-align: center;
  color: #303030 !important;
}

.timerText{
  font-weight: bold;
  font-size: 26px;
  align-items: center;
  text-align: center;
  color: #646464  !important;
}
 


.text1Container {
  background: #E3E3E3;
  height: fit-content;
  width: 90px;
  text-align: center;
  border-radius: 100px;
  padding: 3px 0 3px 0;
}

.normal-font{
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: -0.408px;
  color: #000000;
  margin: 0;
}

.gridContainer {
  display: grid;
  grid-template-columns: 125px auto;
  grid-template-rows: auto;

  grid-gap: 25px;
 
  padding: .50rem;
  width: 100%;
}

.gridContainer img {
  width: 125px;
  height: auto;
  margin: auto;
}

.gridContainer p {
  margin: auto 0;
  font-weight: 700;
}

.gridContainer > div {
  padding: 0.5rem;
}

.gridTable > .gridRow:nth-child(even) {
  background-color: #F4FBFA;
}

.index {
  width: 50px;
  margin: auto auto;
  text-align: center;
}

.gridRow {
  display: flex;
  flex-direction: row;  
}

.instructions {
  max-width: 640px;
}

.welcome {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.75px;
  text-align: center;
}

.apptText {
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.75px;
}

.subText {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.75px;
}

.congratulations {
  font-size: 16px;
  font-weight: normal;
  text-align: center;
  white-space: pre;
  line-height: 21px;
  letter-spacing: -0.32px;
}

.scheduleFirst {
  font-size: 12px;
  font-style: italic;
  text-align: center;
}

.listItem {
  display: flex;
  padding: 16px;

  background: #FFFFFF;
  border: 1px solid #F3F3F3;
  box-sizing: border-box;
  box-shadow: 0px 0px 4px rgba(80, 19, 19, 0.05);
  border-radius: 8px;

  margin-bottom: 16px;
  cursor: pointer;
}

.listItem:hover,
.listItem:focus {
  background-color: #f9f9f9;
}

.left {
  display: flex;
  justify-content: center;
  width: 60px;
}

.left2{
  display: flex;
  justify-content: center;
  width: 60px;
}


.left img {
  height: 40px;
  width: 40px;
  padding: 8px 6px;
  align-self: center;
  border: 1px solid #EECACA;
  box-sizing: border-box;
  border-radius: 3.48235px;
}

.salmon{
  background-color: #F7E0E0;
}

.blue{
  background-color: #E7EFFF;
}

.green{
  background-color: #DBEEDA;
}

.well{
  color: #3E883C;
}

.ok{
  color: #B89725;
}

.sick{
  color: #B85C5C;
}


.gold{
  background-color: #fff;
}

.right {
  display: flex;
  justify-content: flex-end;
  align-self: center;
  width: 40px;
}

.right img {
  height: 12px;
  height: 20.5px;
}

.middle {
  flex: 4 1;
  align-self: center;
}

.stationHeader {
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 22px;
  /* identical to box height, or 129% */
  
  letter-spacing: -0.408px;
  
  /* Label / Light / Primary */
  
  color: #000000;
}


.flex1 {
  flex: 1
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  color: white;
}

.subTitle {
  color: white;
}

.divider {
  width: 1px;
  height: 20px;
  margin-left: 20px;
  margin-right: 20px;
  background-color: #DCDCDC;
}

.scan {
  margin-top: 10px;
  margin-bottom: 10px;
}

.bkg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
}

.inset {
  z-index: 100;
  background: #fff;
  margin: 35px 16px;
  min-height: 200px !important;
  border: 1px solid #F3F3F3;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(80, 19, 19, 0.05);
  border-radius: 8px;
}

.notyet {
  opacity: 0.4;
}

.weAreSorry{
  font-family: SF Pro Text;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 22px;
 
  text-align: center;
  letter-spacing: -0.408px;
  color: #757575;
  white-space: pre-line;
}


.contentContainer {
  /* background: #F3F3F3; */
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 25px;
  padding-top: 0;
  margin-top: 10px;
}



.inputNameContainer {
  display: flex;
  flex-direction: column;
  border: 1px solid #d8d8d8;
  border-radius: 8px;
  min-height: 70px;
}

.MuiAlert-filledError {
  margin-bottom: 4rem;
}

.subContainer {
  margin: 50px;
  max-width: 500px;
}

.scanContainer {
  padding: 25px;
  border-radius: 8px;
}

.scanContainerDefault {
  border: 1px solid #A3A3A3;
}

.scanContainerGreen {
  border: 5px solid rgb(160, 227, 160);;
}

.scanContainerYellow {
  border: 5px solid rgb(242, 242, 121);
}

.scanContainerRed {
  border: 5px solid rgb(242, 79, 79);
}

.yellow{
  background-color: #FFD764;
}


.red{
  background-color: #B85C5C;
}


.scan .subContainer{
  width: 294px;
}

.noticeContainer {
  margin: 32px 5px 32px 5px;
}

.caution {
  display: flex;
  justify-content: center;
  margin: 0 auto 32px auto;
}

.instructionsContainer {
  margin: 50px;
  max-width: 1350px;
}

.sticky {
  position: sticky;
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
  bottom: 0;
  padding-bottom: 1px;
  background-color: #ffffff;
}

@media (max-width: 1024px) {
  .container {
    flex-direction: column;
  }

  .instructionsContainer {
    margin: 2rem;
  }
}

@media (max-width: 768px) {
 
  .caution{
    margin: 16px auto 16px auto;
  }

  .gridContainer {
    display: grid;
    grid-template-rows: 100px 1fr;
    grid-template-columns: auto;
    grid-gap: 5px;
    padding: .50rem;
  }

  .noticeContainer {
    margin-left: 16px;
    margin-right: 16px;
  }

  .gridContainer img {
    width: 150px;
    height: auto;
    margin: auto;
    max-height: 121px;
  }

  .gridContainer p {
    font-size: 14px;
  }


  .index {
    margin: 10px 0;
  }

  .content {
    padding: 24px;
  }

  .flex1 {
    display: flex;
    justify-content: center;
  }

  .instructionsContainer {
    margin: 0.5rem;
    padding-bottom: 1rem;
  }

  .cdcInfoContainer{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .cdcCellText{
    font-family: 'Myriad Pro Condensed';
    font-weight: 500;
    font-size: 10px;
  }

  .cdcHeaderText{
    font-family: 'Myriad Pro Bold';
    font-size: 10px;
  }
}


@media (max-width: 375px) {
  .subContainer {
    margin: 8px;
  }
  .cdcInfoContainer{
    display: flex;
    flex-direction: column;
  }
  .cdcCellText{
    font-family: 'Myriad Pro Condensed';
    font-weight: 500;
    font-size: 10px;
  }

  .cdcHeaderText{
    font-family: 'Myriad Pro Bold';
    font-size: 10px;
  }

  .cdcContainer {
    margin-top: 0.5rem;
    margin-left: 0;
  }

}


