.header {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
    border-bottom: inset;
}
.headerText {
    font-family: "SF Pro Display", "Helvetica", "Arial", sans-serif;;
    font-style: normal;
    font-weight: bold;
    font-size: 34px;
    line-height: 41px;
    margin-bottom: 0;

    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.374px;

    /* Typography/Black */

    color: #000000;
}

.body {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 1rem;
}
.back {
    margin: 2rem;
    cursor: pointer;
}