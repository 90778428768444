.header {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
    border-bottom: inset;
}
.headerText {
    font-family: "SF Pro Display", "Helvetica", "Arial", sans-serif;;
    font-style: normal;
    font-weight: bold;
    font-size: 34px;
    line-height: 41px;
    /* identical to box height, or 121% */

    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.374px;

    /* Typography/Black */

    color: #000000;
}

.body {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 1rem;
}

.bodyText1 {
    font-family: "SF Pro Display", "Helvetica", "Arial", sans-serif;;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    /* identical to box height, or 120% */

    letter-spacing: 0.75px;

    /* Typography/Black */

    color: #000000;
    margin-top: 2rem;
}.bodyText2 {
    font-family: "SF Pro Display", "Helvetica", "Arial", sans-serif;;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    /* identical to box height, or 120% */

    letter-spacing: 0.75px;

    /* Typography/Black */

    color: #000000;
}
.familyMemberListContainer {
    min-width: 35%;
}
.familyMemberCard {
    background: #F3F3F3;
    border-radius: 8px;
    min-width: 20%;
    padding: 1rem;
    margin-block: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-left: #D9D9D9 solid 5px;
}
.familyMemberCardPrimary {
    background: #F3F3F3;
    border-radius: 8px;
    min-width: 20%;
    padding: 1rem;
    margin-block: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-left: #FF9900 solid 5px;
}
.familyMemberCardRight {
    margin-left: 1rem;
}

.familyMemberCardTitle {
    font-family: "SF Pro Display", "Helvetica", "Arial", sans-serif;;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 22px;
    letter-spacing: -0.408px;
    color: #000000;
    margin-bottom: 0;
}
.familyMemberCardSubtitle {
    font-family: "SF Pro Display", "Helvetica", "Arial", sans-serif;;
    font-style: italic;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;    
    letter-spacing: -0.078px;
    color: #777777;
    margin-bottom: 0;
}
.familyMemberCardSubtitle2 {
    font-family: "SF Pro Display", "Helvetica", "Arial", sans-serif;;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;    
    letter-spacing: -0.078px;
    color: #777777;
    margin-bottom: 0;
}
.addFamilyButton {
    margin-bottom: 2rem;
}