.normal-font, .thin-normal-font {
  font-family: Inter;
  font-display: swap;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: -0.408px;
  color: #000000;
  margin: 0;
}

.thin-normal-font {
  font-weight: normal;
}

.small-font {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.078px;
  color: #000000;
  margin: 0;
}


.sub-font, .sub-font-dark {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.078px;
  color: #777777;
  margin: 0;
}

.small-sub-font, .x-small-sub-font {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 21px;
  letter-spacing: -0.32px;
  color: #8A8A8A;
  margin: 0;
}

.x-small-sub-font {
  font-size: 10px;
}

.medium-sub-font, .medium-sub-font-blue {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.24px;
  color: #000000;
  margin: 0;
}

.x-large-font {
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 34px;
  line-height: 41px;
  letter-spacing: 0.374px;
  color: #000000;
  margin: 0;
}

.number-font {
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 38px;
  letter-spacing: 0.87px;
  color: #000000;
}

.large-font {
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: 0.8px;
  color: #000000;
  margin: 0;
}

.medium-font, .medium-font-bold {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.75px;
  color: #000000;
  margin: 0;
}

.medium-font-bold {
  font-weight: bold;
}

.card-font, .card-font-gray {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: -0.32px;
  color: #000000;
  margin: 0;
}

.option-font {
  width: auto;
  overflow: hidden;
  font-size: 1rem;
  box-sizing: border-box;
  min-height: 48px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  padding-top: 6px;
  white-space: nowrap;
  letter-spacing: 0.00938em;
  padding-bottom: 6px;
  padding-left: 1rem;
}

.sub-font-dark {
  color: #000000;
}

.card-font-gray {
  color: #5F5F5F;
}

.medium-sub-font-blue {
  color: var(--blue-color);
}

.small-list-font, .small-list-font-bold {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #000000;
  margin: 0;
}

.small-list-font-bold {
  font-weight: 600;
}


/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
  font-family: 'Myriad Pro Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Myriad Pro Regular'), url('./fonts/MYRIADPRO-REGULAR.woff') format('woff');
  font-display: swap;
  }
  

  @font-face {
  font-family: 'Myriad Pro Condensed';
  font-style: normal;
  font-weight: normal;
  src: local('Myriad Pro Condensed'), url('./fonts/MYRIADPRO-COND.woff') format('woff');
  font-display: swap;
  }
  

  @font-face {
  font-family: 'Myriad Pro Condensed Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Myriad Pro Condensed Italic'), url('./fonts/MYRIADPRO-CONDIT.woff') format('woff');
  font-display: swap;
  }
  

  @font-face {
  font-family: 'Myriad Pro Light';
  font-style: normal;
  font-weight: normal;
  src: local('Myriad Pro Light'), url('./fonts/MyriadPro-Light.woff') format('woff');
  font-display: swap;
  }
  

  @font-face {
  font-family: 'Myriad Pro Semibold';
  font-style: normal;
  font-weight: normal;
  src: local('Myriad Pro Semibold'), url('./fonts/MYRIADPRO-SEMIBOLD.woff') format('woff');
  font-display: swap;
  }
  

  @font-face {
  font-family: 'Myriad Pro Semibold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Myriad Pro Semibold Italic'), url('./fonts/MYRIADPRO-SEMIBOLDIT.woff') format('woff');
  font-display: swap;
  }
  

  @font-face {
  font-family: 'Myriad Pro Bold Condensed';
  font-style: normal;
  font-weight: normal;
  src: local('Myriad Pro Bold Condensed'), url('./fonts/MYRIADPRO-BOLDCOND.woff') format('woff');
  font-display: swap;
  }
  

  @font-face {
  font-family: 'Myriad Pro Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Myriad Pro Bold'), url('./fonts/MYRIADPRO-BOLD.woff') format('woff');
  font-display: swap;
  }
  

  @font-face {
  font-family: 'Myriad Pro Bold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Myriad Pro Bold Italic'), url('./fonts/MYRIADPRO-BOLDIT.woff') format('woff');
  font-display: swap;
  }
  

  @font-face {
  font-family: 'Myriad Pro Bold Condensed Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Myriad Pro Bold Condensed Italic'), url('./fonts/MYRIADPRO-BOLDCONDIT.woff') format('woff');
  font-display: swap;
  }
