@import "../../fonts.css";

.strokeContainer {
  width: 100%;
  height: 56px;
  background: #ffffff;
  border: 1.25px solid #777777 !important;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
  border-radius: 14px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.disabledStrokeButton {
  color: #777777 !important;
}

.strokeContainer > img {
  margin-right: 10.75px;
}

.dashedContainer {
  width: 343px;
  height: 46px;
  background: #ffffff;
  border: 1.25px dashed #757575 !important;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
  border-radius: 14px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.dashedContainer > img {
  margin-right: 10.75px;
}
button.smallButton {
  font-family: Inter;
  background-color: #01a89c;
  border-radius: 100px;
  border: 1px;
  width: 90px;
  height: 31px;
  color: #fff;
  font-family: SF Pro Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: -0.07800000160932541px;
  text-align: center;
}

.bigButtonContainer {
  background: var(--success-color);
  text-align: center;
  height: 210px;
  width: 210px;
  position: relative;
  justify-content: center;
  border-radius: 8px;
  cursor: pointer;
  overflow: hidden;
}

.bigButtonContainer > div:last-of-type > p {
  z-index: 1;
}

.bigButtonContainer:hover {
  opacity: 0.5;
}

.imgButton {
  position: absolute;
  bottom: 0;
  /* z-index: -1; */
}

.multipleButton {
  background-color: transparent;
  text-align: center;
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 7px;
  border-style: solid;
  border-width: 1px;
  border-color: #7b7b7b;
  width: 75px;
  cursor: pointer;
  margin: 3px;
}

.multipleButtonSelected {
  background-color: var(--primary-color);
  border-style: none;
}

.multipleButton > p {
  color: var(--text-gray-color);
}

.multipleButtonSelected > p {
  color: #000000;
}

.buttonContainer {
  background-color: var(--primary-color);
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  cursor: pointer;
  min-width: fit-content;
  padding: 10px;
}

.buttonDisabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.buttonsTextContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  height: 100%;
}

.buttonHeader {
  /* align-self: center; */
  text-align: center;
  /* flex: 0 0 50%; */
}

.needsUpdatesButton {
  color: white;
  background-color: red;
  align-self: flex-start;
  flex: 0 0 25%;
  border-radius: 8px;
}

.needsUpdatesText {
  font-size: 0.9rem;
  font-weight: 600;
  margin-left: 0.1rem;
  margin-right: 0.1rem;
  margin-top: 0.1rem;
  margin-bottom: 0.1rem;
}

@media (max-width: 1024px) {
  .bigButtonContainer {
    background: var(--success-color);
    text-align: center;
    height: 200px;
    width: 180px;
    position: relative;
    justify-content: center;
    border-radius: 8px;
    cursor: pointer;
    overflow: hidden;
  }
}

@media (max-width: 600px) {
  .bigButtonContainer {
    background: var(--success-color);
    width: 100%;
  }
}
