.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  height: 100%;
}
.innerContainer {
  margin-bottom: 20%;
  background: #f3f3f3;
  border-radius: 8px;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.headerText {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.75px;
  color: #000000;
}

.gxLogo {
  width: 320px;
  height: "auto";
}

@media (max-width: 780px) {
  .container {
    margin-top: 5%;
  }
}

@media (max-width: 480px) {
  .container {
    padding: 2rem 0.25rem 0.25rem 0.25rem;
  }
  .innerContainer {
    padding: 0.5rem;
  }
  .headerText {
    font-size: 16px;
    line-height: 18px;
  }
  .shopLogo {
    width: 250px;
    height: "auto";
  }

  .gxLogo {
    width: 150px;
    height: "auto";
  }
}

@media (max-width: 300px) {
  .headerText {
    font-size: 14px;
    line-height: 18px;
  }

  .shopLogo {
    width: 200px;
    height: "auto";
  }

  .gxLogo {
    width: 100px;
    height: "auto";
  }
}
