@import '../../fonts.css';
@import '../../index.css';

.container {
  display: flex;
  padding-left: 0 !important;
  padding-right: 0 !important;
  height: 100%;
  padding-bottom: 50px;
}

.subContainer {
  margin-top: 25px;
  margin-left: 58px;
  max-width: 700px;
}

.rightContainer {
  max-width: 50%;
  min-width: 400px;
  border-left-width: 1px;
  border-color: var(--light-gray-color);
  border-left-style: solid;
  padding: 2rem;
}

.submit-vaccine-button {
  background: rgba(255, 153, 0, 0.8);
  border: 1.25px solid #FF9900;
  border-radius: 14px;
  width: 326px;
  color: #000;
}

.vaxTable {
  width: 100%;
  justify-content: space-between;

}

.flex1 {
  flex: 1
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.contentContainer {
  /* background: #F3F3F3; */
  border: 1px solid dashed;
  border-radius: 8px;
  padding: 25px;
  padding-top: 0;
  margin-top: 3rem;
}

.inputfile {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

.inputfileerror {
  color: red;
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

.inputfile + label {
	cursor: pointer; /* "hand" cursor */
}

.inputfile:focus + label,
.inputfile + label:hover {
    background-color: red;
}

.inputfile + label {
  font-size: 1.25em;
  font-weight: 700;
  color: white;
  background-color: black;
  display: inline-block;
  margin-top: 3rem;
  padding: 0.5rem;
}

.customFileUpload {
  display: inline-block;
  cursor: pointer;
  font-weight: 600;
  background-color: #fff;
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  cursor: pointer;
  min-width: -moz-fit-content;
  min-width: fit-content;
  padding: 10px;
  border: 1px black dashed;
  
}

.hasInsurance {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 3rem;
  text-align: center;
}

.insuranceTitle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.nonInsuranceTitle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.addInsurancePlanLink {
  color: blue;
  cursor: pointer;
}

@media (max-width: 1024px) {
  .container {
    flex-direction: column;
  }

  .subContainer {
    margin: 50px;

  }
}

@media (max-width: 768px) {
  .subContainer {
    margin: 25px;
    width: 400px;
  }

  .flex1 {
    display: flex;
    justify-content: center;
  }

  .inputNameContainer {
    flex-direction: column;
  }
}

ul {
  list-style-type: none; /* Remove bullets */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove margins */
}
.add-vaccine-button {
  max-width: 375px;
}

.datePicker {
  width: 100%;
}
 div > input {
  width: 100%;
  border-bottom: 1px solid #000;
  border-top: 0px solid #000;
  border-left: 0px solid #000;
  border-right: 0px solid #000;

}

.datePickerLabel {
  color: black;
  font-size: .85rem;
  line-height: 1;
  opacity: .5;
}
