@import "../../../../index.css";
@import "../../../../fonts.css";

.calendar-container {
  width: 70% !important;
  border: 2px solid #7777;
  border-radius: 4px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 2px;
  background: white !important;
}

@media (max-width: 1280px) {
  .calendar-container {
    width: 100% !important;
  }
}

.react-calendar {
  border: none !important;
}

.react-calendar__navigation {
  display: none !important;
}

.react-calendar__tile.react-calendar__month-view__days__day {
  /* border: 1.2px solid #dfdfdf; */
  margin-top: -1px;
  margin-left: -1px;
  background: white;
  border: none;
  padding: 18px;
}

abbr:hover {
  width: 56px;
  height: 56px;
  background: linear-gradient(
      0deg,
      rgba(255, 153, 0, 0.8),
      rgba(255, 153, 0, 0.8)
    ),
    #ffffff;

  border: 2px solid #ff9900;
  box-sizing: border-box;
  border-radius: 50%;
  padding: 10px;
}

@media (max-width: 740px) {
  abbr:hover {
    padding: 5px;
  }
}

abbr[data-original-title],
abbr[title] {
  text-decoration: none !important;
  font-style: normal;
  font-weight: 600;
  font-size: 14.7px;
  line-height: 18px;
  font-feature-settings: "tnum" on, "lnum" on;
}

.schedule {
  background-color: orange;
}

.react-calendar__month-view__weekdays {
  text-transform: none !important;
}

.react-calendar__month-view__days__day--weekend {
  color: black !important;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: black !important;
}

.react-calendar__tile {
  color: black !important;
}

.react-calendar__tile--active abbr {
  background: linear-gradient(
      0deg,
      rgba(255, 153, 0, 0.8),
      rgba(255, 153, 0, 0.8)
    ),
    #ffffff !important;
  border: 2px solid #ff9900;
  box-sizing: border-box;
  border-radius: 50%;
  padding: 10px;
}

@media (max-width: 740px) {
  .react-calendar__tile--active abbr {
    padding: 5px;
  }
}

.nav-container {
  display: flex;
  justify-content: space-between;
  width: 48px;
}

.calendar-header {
  background: white !important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 30px;
}

.nav-container img {
  max-width: 9px;
}

.nav-container img:hover {
  color: rgb(163, 163, 163);
  opacity: 0.5;
}

.calendar-header span {
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
}

.react-calendar {
  width: 100%;
}

.react-calendar button {
  border: none !important;
  outline: none !important;
  background-color: white !important;
}