.page {
    display: flex;
    flex-direction: row;
}
.container {
    padding: 1rem;
    padding-right: 10%;
}
.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.search {
    background: #F3F3F3;
    border-radius: 10px;
    flex-direction: row;
    display: flex;
    padding: .25rem;
}
.searchInput {
    min-width: 6rem;
    background: #F3F3F3;
    border-radius: 10px;
    border: none;
    padding: 0.25rem;
    outline: none !important;
}
.searchInput:focus {
    min-width: 6rem;
    background: #F3F3F3;
    border-radius: 10px;
    border: none !important;
    padding: .25rem;
    outline: none !important;
}
.sorter {
    border: 1px solid #777777;
    box-sizing: border-box;
    border-radius: 30px;
    min-width: 4rem;
    padding: 0.25rem;
    display: flex;
    flex-direction: row;
}
.sorterText {
    margin-bottom: 0rem;
    margin-inline: 0.25rem;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.75px;
    color: #777777;
}
.sorterArrowUp {
    transform: rotate(-180deg);
    margin-right: 2rem;
}
.sorterArrow {
    margin-right: 2rem;
}
.list {
    padding: 1rem;
}
.item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 0.5px solid #777777;
    padding: 0.5rem;
    cursor: pointer;
}
.date {
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 22px;
    display: flex;
    align-items: flex-end;
    letter-spacing: -0.408px;
    color: #000000;
    margin-bottom: .1rem;
}
.chip {
    padding: .5rem;
    margin-right: 1rem;
    border-radius: 100px;
}
.chipText {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #000000;
    margin-bottom: 0;
}
.itemHeaderRight {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}
.pageRight {
    background: #F5F5F5;
    /* Greyscale/Grey 4 */
    border: 1.5px solid #E8E8E8;
    max-width: 40%;
    min-width: 30%;
  }
  .closeButton {
    cursor: pointer;
    margin: 1rem;
  }
  .selectedDate {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    /* identical to box height, or 120% */
    
    letter-spacing: 0.75px;
    
    /* Typography/Black */
    
    color: #000000;
    margin-bottom: 1rem;
    margin-left: 2rem;
  }
  .selectionText {
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 22px;
    /* identical to box height, or 129% */
    
    text-align: center;
    letter-spacing: -0.408px;
    
    /* Typography/Black */
    
    color: #000000;
    border-bottom: inset;
    padding-bottom: 1rem;    
  }
  .symptomItem {
      margin-inline: 2rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 1rem;
      border-bottom: inset;
    }
  .symptom {
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 22px;
    /* identical to box height, or 129% */
    
    letter-spacing: -0.408px;
    
    /* Typography/Black */
    
    color: #000000;
    margin-left: 1rem;
    margin-bottom: 0;
  }