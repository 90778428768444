@import "../../fonts.css";
@import "../../index.css";

.container {
  /* display: flex; */
  padding-left: 2.2rem !important;
  padding-right: 1rem !important;
  height: 100%;
}

.container1 {
  padding-left: 60px;
  padding-right: 50px;
}

.familyGrid {
  margin-top: 0.5 rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(5, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
}

.familyCard {
  background: #f3f3f3;
  border-radius: 8px 8px 0px 0px;
  display: flex;
  flex-direction: column;
}

.familyCardTop {
  flex-direction: row;
  display: flex;
  padding: 1rem;
}

.familyCardBottom {
  background-color: white;
  padding: 1rem;
  border: 1px solid #f3f3f3;
  box-sizing: border-box;
  border-radius: 0px 0px 8px 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.resultTag {
  display: flex;
}

.familyCardBottomRight {
  display: flex;
  flex-direction: row;
}

.familyCardPrimary {
  border-left: #ff9900 solid 5px;
  border-bottom-left-radius: 8px;
}

.familyCardLeft {
  flex: 4;
}

.familyCardRight {
  flex: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: fit-content;
}

.mostRecentContainer {
  width: fit-content;
  display: inline;
}

.mostRecent {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  display: inline;
  /* identical to box height, or 133% */

  /* Typography/Inactive Lable */

  color: #777777;
}

.mostRecentValue {
  display: inline;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  /* or 138% */
  letter-spacing: -0.078px;

  /* Typography/Black */

  color: #000000;
}

.resultChip {
  display: flex;
  align-items: center;
  background: #e8e8e8;
  border-radius: 100px;
  padding-inline: 0.5rem;
  padding-block: 0.25rem;
  margin-right: 0.5rem;
}

.resultChipText {
  /* display: inline; */
  /* align-self: flex-end; */
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  /* or 129% */
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.078px;
  margin-bottom: 0;
  padding: 0 6px;
}

.name {
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 28px;
  /* identical to box height, or 127% */

  letter-spacing: 0.8px;
  margin-top: 1rem;
}

.primaryAccount {
  font-style: italic;
  font-weight: normal;
  font-size: 16px;
  color: #777777;
}

.dob {
  font-weight: normal;
  font-size: 16px;
  color: #777777;
}

.flex1 {
  flex: 1;
}

.containerHeader {
  max-width: 375px;
  align-self: center;
  margin: auto;
  padding: 0 1rem;
}

.listContainer {
  padding-top: 43px;
}

.sideContainer {
  border-left-width: 1px;
  border-color: var(--light-gray-color);
  border-left-style: solid;
}

.title {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */

  letter-spacing: 0.75px;

  color: #000000;
  display: block;
  padding-bottom: 24px;
}

.titleContainer {
  margin-top: 43px;
  margin-left: 36px;
  margin-bottom: 14px;
}

.sideContentContainer {
  margin-left: 36px;
  margin-right: 36px;
  margin-top: 29px;
}

.icon {
  width: 18px;
  margin-left: 10px;
}

.strokeButton {
  margin-top: 30px;
}

@media (max-width: 1400px) {
  
.familyGrid {
  margin-top: 0.5 rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(5, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
}
}

@media (max-width: 1330px) {
  .container {
    flex-direction: column;
  }

  .sideContainer {
    border-left-style: none;
  }

  .sideContentContainer {
    margin-left: 54px;
    margin-right: 54px;
  }

  .titleContainer {
    margin-left: 54px;
  }

  .container1 {
    padding: 54px;
    padding-top: 0;
  }

  .flex1 {
    flex: 0;
  }
}

@media (max-width: 998px) {
  .familyGrid {
    margin-top: 0.5 rem;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(5, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 10px;
  }
}

@media (max-width: 768px) {
  .sideContentContainer {
    margin-left: 25px;
    margin-right: 25px;
  }

  .titleContainer {
    margin-left: 25px;
  }

  .container1 {
    padding: 25px;
    padding-top: 0;
  }

  .flex1 {
    flex: 0;
  }

  .container{
    padding-left: 1rem !important;
  }
  .addFamilyMemberButton{
    margin: 1rem;
  }
}

.QRButton {
  cursor: pointer;
}

.modalHeader {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  border-bottom: black solid 1px;
}

.modalHeaderTitle {
  width: 100%;
  text-align: center;
  margin-bottom: 0;
  font-family: Arial;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 28px;
  /* or 127% */

  text-align: center;
  letter-spacing: 0.8px;

  /* Typography/Black */

  color: #000000;
}

.modalCloseButton {
  cursor: pointer;
  justify-self: flex-start;
}

.modalItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1rem;
  border-bottom: black solid 1px;
  cursor: pointer;
}

.modalItemText {
  margin-left: 1rem;
  margin-bottom: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 22px;
  /* or 129% */

  display: flex;
  align-items: center;
  letter-spacing: -0.408px;

  /* Typography/Black */

  color: #000000;
}
.addFamilyMemberButton {
  border: 1px solid #e8e8e8;
  box-sizing: border-box;
  border-radius: 14px;
  padding-inline: 0.5rem;
  padding-block: 0.25rem;
}
.form {
  max-width: 50%;
  padding: 1rem;
}
.addFamilyHeaderText {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.75px;
  color: #000000;
}
.addFamilySubmit {
  width: 100%;
  background: linear-gradient(
      0deg,
      rgba(255, 153, 0, 0.8),
      rgba(255, 153, 0, 0.8)
    ),
    #ffffff !important;
  /* Aura Orange/Primary */

  border: 1.25px solid #ff9900 !important;
  box-sizing: border-box;
  border-radius: 14px !important;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  letter-spacing: -0.408px;

  /* Typography/Black */

  color: #000000 !important;
}

.bg_pink {
  background: "#FF77C0"
}

.bg_gray_light {
  background: #f3f3f3;
}


.consent_required {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 0.8px;
}

.consent_sign {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.8px;
}