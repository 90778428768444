.IsInsured_header {
  font-style: normal;
  font-weight: 700;
  font-size: 34px;
  line-height: 41px;
  letter-spacing: 0.374px;
  color: #000000;
}

.IsInsured_ask {
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 33px;
  text-align: center;
  letter-spacing: 0.87px;
  color: #000000;
}

.addition_form {
  width: 30%;
  padding: 0 24px;
}

.or_text {
  font-weight: 700;
  color: #777777;
  font-size: 34px;
  line-height: 120%;
}

.IsInsured_selectedBtn {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 10px;
  height: 55px;
  background: linear-gradient(
      0deg,
      rgba(255, 153, 0, 0.8),
      rgba(255, 153, 0, 0.8)
    ),
    #ffffff;
  border-radius: 100px;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.32px;
  color: #000000;
}

.IsInsured_notSelectBtn {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 10px;
  height: 55px;
  background: #e8e8e8;
  border-radius: 100px;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.32px;
  color: #777777;
}

.IsInsured_next {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 46px;
  background: linear-gradient(
      0deg,
      rgba(255, 153, 0, 0.8),
      rgba(255, 153, 0, 0.8)
    ),
    #ffffff;
  border: 1.25px solid #ff9900;
  box-sizing: border-box;
  border-radius: 14px;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.408px;
  color: #000000;
  cursor: pointer;
}

.IsInsured_next:hover {
  border: 4px solid #ff9900;
}

.InsuranceCompleted {
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 38px;
  text-align: center;
  letter-spacing: 0.374px;
  color: #000000;
}

.back-button-logo {
  width: 45%;
  display: flex;
  justify-content: center;
}

.header-text {
  display: flex;
  align-items: center;
}

@media (max-width: 950px) {
  .InsuranceCompleted {
    font-weight: 600;
    font-size: 22px;
    line-height: 21px;
  }

  .back-button-logo {
    width: 0%;
    display: flex;
    justify-content: start;
  }

  .header-text {
    justify-content: center;
  }
}

@media (max-width: 768px) {
  .IsInsured_header {
    font-weight: 600;
    font-size: 24px;
    line-height: 21px;
  }
  .IsInsured_ask {
    font-weight: 600;
    font-size: 20px;
    line-height: 21px;
    letter-spacing: 0.374px;
  }
  .addition_form {
    width: 100%;
    padding: 0 24px;
  }
}

@media (max-width: 600px) {
  .IsInsured_header {
    font-size: 18px;
  }
}

.insurance-input {
  border: none;
  border-bottom: 1px solid #777777;
  padding: 0.5rem 0;
  width: 100%;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.408px;
  color: #000000;
  font-weight: 600;
  outline: none;
}

.insurance-input input:focus {
  outline: none;
}

.insurance-label-disable {
  color: black;
  opacity: 0.6;
}

.insurance-input-disable {
  border: none;
  border-bottom: 1px solid #777777;
  padding: 0.5rem 0;
  width: 100%;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.408px;
  color: #000000;
  font-weight: 600;
  outline: none;
  opacity: 0.6;
}
