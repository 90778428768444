@import '../../fonts.css';
@import '../../index.css';

.rowContainer {
  background-color: #F5F5F5;
  padding: 9px 25px 9px 25px;
  cursor: pointer;
}

.rowContainer:nth-child(even) {
  background-color: #FFFFFF;
}

.tableFooter {
  /* padding-left: 25px; */
}

.tableFooter > div:first-child >p {
  padding-left: 25px;
}