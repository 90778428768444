@import "../../fonts.css";
@import "../../index.css";

.container {
  display: flex;
  padding-left: 0 !important;
  padding-right: 0 !important;
  height: 100%;
  padding-bottom: 50px;
}

.subContainer {
  margin-top: 0px;
  margin-left: 34px;
  max-width: 800px;
  width: 100%;
}

.breadContainer {
  margin-left: 34px;
}

.flex1 {
  flex: 1;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 34px;
}

.header > p {
  cursor: pointer;
}

.contentContainer {
  background: #f3f3f3;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 25px;
  padding-top: 0;
  margin-top: 10px;
}

.insuranceCardContainer {
  display: flex;
  padding-top: 0;
  margin-top: 10px;
}

.insuranceCard {
  max-width: 90%;
  max-height: 90%;
}

@media (max-width: 1024px) {
  .container {
    flex-direction: column;
  }

  .subContainer {
    margin: 50px;
  }

  .insuranceCard {
    max-width: 100%;
    max-height: 100%;
  }
}

@media (max-width: 768px) {
  .subContainer {
    margin: 25px;
    width: 400px;
  }

  .flex1 {
    display: flex;
    justify-content: center;
  }

  .insuranceCard {
    max-width: 100%;
    max-height: 100%;
  }
}

.policyHolderText {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  /* identical to box height, or 138% */

  letter-spacing: -0.078px;

  /* Typography/Inactive Lable */

  color: #777777;
}
.policyNameText {
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  /* or 129% */

  display: flex;
  align-items: center;
  letter-spacing: -0.408px;

  /* Typography/Black */

  color: #000000;
  margin-bottom: 0;
}

.policyCard {
  border: 1px solid #f3f3f3;
  border-radius: 8px;
  margin-block: 1rem;
  max-width: 500px;
  width: 100%;
}
.policyCardExpanded {
  border: 1px solid #f3f3f3;
  border-radius: 8px;
  margin-block: 1rem;
  max-width: 800px;
  width: 100%;
}
.policyCardTop {
  background: #f3f3f3;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}
.policyCardBottom {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 1rem;
}
.policyCardInfo {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  position: relative;
}

.insuranceChip {
  margin-right: 1rem;
  padding: 0.3rem;
  background-color: #ffe794;
  border-radius: 100px;
  flex-direction: row;
  align-items: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-block: 0.2rem;
}
.insuranceChipText {
  margin-bottom: 0;
  margin-inline: 0.2rem;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  /* identical to box height, or 131% */

  letter-spacing: -0.32px;

  /* Typography/Black */

  color: #000000;
}

.addInsuranceContainer {
  /* background: #f3f3f3; */
  border: solid 2px;
  border-color: #f3f3f3;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1rem;
  width: 42%;
}
.addInsuranceContainerRight {
  flex-direction: column;
}
.addInsuranceText {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  /* or 133% */

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.24px;

  /* Typography/Inactive Lable */

  color: #777777;
}
.addInsuranceButton {
  border: 1.5px dashed #d9d9d9 !important;
  box-sizing: border-box;
  border-radius: 14px !important;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.inputItem {
  flex: 1;
  margin-right: 1rem;
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.formContainer {
  width: 100%;
}
.editButton {
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 99;
  cursor: pointer;
}
.saveButtonDisabled {
  background: linear-gradient(
      0deg,
      rgba(255, 153, 0, 0.8),
      rgba(255, 153, 0, 0.8)
    ),
    #ffffff;
  opacity: 0.4;
  /* Aura Orange/Primary */

  border: 1.25px solid #ff9900;
  box-sizing: border-box;
  border-radius: 14px;
  width: 100%;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  padding-block: 0.5rem;
  cursor: initial;
}

.saveButton {
  background: linear-gradient(
      0deg,
      rgba(255, 153, 0, 0.8),
      rgba(255, 153, 0, 0.8)
    ),
    #ffffff;
  /* Aura Orange/Primary */

  border: 1.25px solid #ff9900;
  box-sizing: border-box;
  border-radius: 14px;
  width: 100%;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  padding-block: 0.5rem;
}
.uploadPhotoButton {
  width: 100%;
}
.imagePreview {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #f3f3f3;
  border-radius: 8px;
  width: 100%;
  margin-bottom: 1rem;
}
.replaceButton {
  margin-left: 1rem;
  cursor: pointer;
}

.tabText {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.75px;
  color: #8b8b8b;
}

.sideMenu {
  display: block;
}
.sideMenu2 {
  display: none;
}

.selected-button {
  height: 31px;
  background: linear-gradient(
      0deg,
      rgba(255, 153, 0, 0.8),
      rgba(255, 153, 0, 0.8)
    ),
    #ffffff;
  border: 1px solid #ff9900;
  box-sizing: border-box;
  border-radius: 100px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: -0.32px;
  color: #000000;
  cursor: pointer;
  padding: 5px 11px;
  width: 164px;
}

.unSelected-button {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  letter-spacing: -0.32px;
  color: #000000;
  padding: 5px 11px;
  width: 164px;
  height: 31px;
  background: #e3e3e3;
  border-radius: 100px;
  cursor: pointer;
}

.policyCardFamily {
  border: 1px solid #f3f3f3;
  border-radius: 8px;
  margin-block: 1rem;
  max-width: 500px;
  width: 100%;
  align-self: start;
  margin-top: 2rem;
}

.familyPolicyCardTopLeft {
  display: flex;
  flex-direction: column;
}

.familyCardHeader {
  display: flex;
  justify-content: space-between;
}

.editText {
  color: #405add;
  font-weight: 600;
  font-size: 17px;
  line-height: 130%;
}

.insuranceChipFamily {
  margin: 0.5rem 1rem;
  padding: 0.3rem;
  background-color: #ffe794;
  border-radius: 100px;
  display: inline-flex;
  flex-direction: row;
}

@media (max-width: 1024px) {
  .addInsuranceContainer {
    /* background: #f3f3f3; */
    border: solid 2px;
    border-color: #f3f3f3;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1rem;
    width: 60%;
  }
}

@media (max-width: 768px) {
  .addInsuranceContainer {
    /* background: #f3f3f3; */
    border: solid 2px;
    border-color: #f3f3f3;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.5rem;
    width: 80%;
  }
}

@media (max-width: 420px) {
  .sideMenu {
    display: none;
  }
  .sideMenu2 {
    display: block;
  }
  .addInsuranceContainer {
    /* background: #f3f3f3; */
    border: solid 2px;
    border-color: #f3f3f3;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.5rem;
    width: 100%;
  }
}

.policyCardTopFamily {
  background: #f3f3f3;
  padding: 1rem;
}
