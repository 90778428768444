@import "../../fonts.css";
@import "../../index.css";

.container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 13px;
  margin-top: 20px;
  align-items: center;
  cursor: pointer;
}

.signContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row !important;
}

.container1 {
  display: flex;
  justify-content: space-between;
  padding: 17px 2px 17px 0;
  cursor: pointer;
}

.containerFull {
  display: flex;
  justify-content: space-between;
  padding: 17px 0 17px 0;
}

.container1:hover {
  background-color: #f9f9f9;
}

.selected {
  background-color: #f9f9f9;
}

.right {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.left {
  flex: 2;
}

.flex {
  display: flex;
}

.flexAlignCenter {
  display: flex;
  align-items: center;
}

.nameColumn {
  flex: 2 1 0;
}

.qrColumn {
  flex: 1 1 0;
}

.signColumn {
  flex: 1 1 0;
  margin-left: 6px;
}

.circle {
  width: 18px;
  height: 18px;
  background-color: transparent;
  border-radius: 50%;
}

.circleContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 21px 0 21px;
}

.text1Container {
  background: #e3e3e3;
  height: fit-content;
  width: max-content;
  text-align: center;
  border-radius: 100px;
  padding: 7px 10px;
}

.icon {
  margin-left: 9px;
}

.orange {
  background-color: var(--primary-color);
}

/* .divider {
  margin-left: 60px;
  margin-right: 50px;
} */

.mediumSubFont {
  margin-bottom: 5px !important;
}

.normalFont {
  margin-bottom: 15px !important;
}

.listSimpleContainer {
  padding-top: 20px;
}

.input {
  border-width: 0;
  background-color: transparent;
  margin-top: -10px;
  outline: none;
}

.inputName {
  margin-bottom: 5px !important;
}

.certificationImg {
  width: 45px;
  max-height: 45px;
  margin-right: 11px;
}

.listFormFont {
  font-family: Inter !important;
  font-style: normal !important;
  font-size: 17px !important;
  font-weight: 600 !important;
  line-height: 22px !important;
  letter-spacing: -0.408px !important;
  color: #000000 !important;
  margin-bottom: 5px !important;
}

.normalFont > p {
  margin-bottom: 0.3rem;
}

input[type="date"]:required:invalid::-webkit-datetime-edit {
  color: transparent;
}
input[type="date"]:focus::-webkit-datetime-edit {
  color: black !important;
}

.greenText {
  color: rgb(160, 227, 160);
}

.yellowText {
  color: rgb(242, 242, 121);
}

.redText {
  color: rgb(242, 79, 79);
}

@media (max-width: 500px) {
  .signContainer {
    flex-direction: column !important;
  }
}
