.form-select__control {
	border: none !important;
	border-bottom: 1px solid gray !important;
	border-radius: 0 !important;
	outline: none;
}

.form-select__control:focus {
	outline: none;
}

.form-select__control:hover {
	border-color: gray;
}

.form-select__control.form-select__control--is-focused {
	box-shadow: none;
}

/* @media (min-width: 762px) {
	.form-select__control {
		height: 5rem;
	}
} */

.form-select__control .form-select__indicator-separator {
	display: none;
}

.form-select__control .form-select__placeholder {
	color: black;
}

.form-select__value-container {
  padding-left: 0px !important;
}

.form-select__placeholder {
  color: gray !important;
}

.auraField {
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
}

.auraField label {
  color: black;
  font-size: .85rem;
  line-height: 1;
  opacity: .5;
}

.auraField .withError {
  border-bottom: 1px solid red;
}

.auraField input {
  border: none;
  border-bottom: 1px solid black;
  color: black;
  font-size: 1rem;
  letter-spacing: .1px;
  line-height: 1;
  margin-bottom: .3rem;
  padding: .5rem 0;
  width: 100%;
  font-weight: 600;
}

.auraField .input {
  border: none;
  border-bottom: 1px solid light-gray;
  color: black;
  font-size: 1rem;
  letter-spacing: .1px;
  line-height: 1;
  margin-bottom: .5rem;
  padding: 1rem 0;
  width: 100%;
}

.auraField input:disabled,
.auraField input.disabled {
  color: mid-gray;
  cursor: not-allowed;
}

.auraField input.withError {
  border-bottom: 1px solid red;
}

.auraField input:focus {
  outline: none;
}

.auraField input::placeholder {
  opacity: .5;
}

.auraField div span {
  color: red;
  font-size: .8rem;
  font-weight: 600;
}

.auraField span {
  color: red;
  font-size: .8rem;
  font-weight: 600;
}

.auraFieldError {
  color: red;
  font-size: 1rem;
  font-weight: 600;
}

.auraForm {
  padding: .5rem 2rem 2.5rem;
}

.auraForm .button-section {
  display: flex;
  justify-content: space-between;
}
.auraForm .button-section .btn {
  flex: 1;
}
.auraForm .button-section .btn:first-of-type {
  margin-right: 1rem;
  margin-left: 1rem;
}

.auraForm .loading-wrapper {
  align-items: center;
  display: flex;
  width: 100%;
}
