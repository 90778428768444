.IsVaccinated_header {
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    line-height: 41px;
    letter-spacing: 0.374px;
    color: #000000;
}



.IsVaccinated_ask {
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 33px;
    text-align: center;
    letter-spacing: 0.87px;
    color: #000000;
}

@media (max-width: 768px) {
  .IsVaccinated_header {
    font-weight: 600;
    font-size: 24px;
    line-height: 21px;
  }
  .IsVaccinated_ask {
    font-weight: 600;
    font-size: 20px;
    line-height: 21px;
    letter-spacing: 0.374px;
  }
}

.IsVaccinated_selectedBtn {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 10px;
    height: 55px;
    background: linear-gradient(
        0deg,
        rgba(255, 153, 0, 0.8),
        rgba(255, 153, 0, 0.8)
      ),
      #ffffff;
    border-radius: 100px;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.32px;
    color: #000000;
  }
  
  .IsVaccinated_notSelectBtn {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 10px;
    height: 55px;
    background: #e8e8e8;
    border-radius: 100px;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.32px;
    color: #777777;
  }

  .IsVaccinated_next {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 46px;
    background: linear-gradient(
        0deg,
        rgba(255, 153, 0, 0.8),
        rgba(255, 153, 0, 0.8)
      ),
      #ffffff;
    border: 1.25px solid #ff9900;
    box-sizing: border-box;
    border-radius: 14px;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.408px;
    color: #000000;
    cursor: pointer;
  }
  
  .IsVaccinated_next:hover {
    border: 4px solid #ff9900;
  }