@import "../../index.css";

.back-button {
  cursor: pointer;
}

.back-button-text {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.75px;
  color: #8b8b8b;
}

.Sign_btn {
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  align-items: center;
  text-align: center;
  letter-spacing: -0.408px;
  color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  height: 50px;
  background: #ffffff;
  border: 1.25px solid #000000;
  box-sizing: border-box;
  border-radius: 14px;
  cursor: pointer;
}

.Sign_btn:hover {
  background: linear-gradient(
      0deg,
      rgba(255, 153, 0, 0.8),
      rgba(255, 153, 0, 0.8)
    ),
    #ffffff;
  border: 2px solid #ff9900;
}

@media (max-width: 576px) {
  .Sign_btn {
    background: linear-gradient(
        0deg,
        rgba(255, 153, 0, 0.8),
        rgba(255, 153, 0, 0.8)
      ),
      #ffffff;
    border: 2px solid #ff9900;
  }
}

.form_container_header {
  font-style: normal;
  font-weight: bold;
  font-size: 34px;
  line-height: 41px;
  letter-spacing: 0.374px;
  color: #000000;
}

.form_container_sub-header {
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: -0.408px;
  color: #777777;
}

.text_ {
  font-weight: 500;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: 0.408px;
  color: #000000;
}

.btn_submit_ {
  outline: none;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  width: 100%;
  background: linear-gradient(
      0deg,
      rgba(255, 153, 0, 0.8),
      rgba(255, 153, 0, 0.8)
    ),
    #ffffff;
  border: 1.25px solid #ff9900;
  box-sizing: border-box;
  border-radius: 14px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px;
  margin-top: -5px;
  height: 46px;
}

.btn_submit_:focus {
  border: 2px solid #ff9900;
  outline: none;
}

.ConsentForm_header2 {
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 33px;
  letter-spacing: 0.87px;
  color: #000000;
}

.ConsentForm_header {
  font-style: normal;
  font-weight: 600;
  font-size: 34px;
  line-height: 41px;
  text-align: center;
  letter-spacing: 0.374px;
  color: #000000;
}

.desc {
  /* font-family: SF Pro Text; */
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: -0.408px;
  color: #000000;
}

.note_header {
  /* font-family: SF Pro Display; */
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: 0.352px;
  color: #000000;
}

.points {
  /* font-family: SF Pro Text; */
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: -0.408px;
  color: #000000;
}

.points_header {
  font-weight: 500;
}

.ul_ {
  list-style-type: disc;
  padding-left: 35px !important;
}

.ConsentForm_container {
  max-width: 65%;
}

@media (max-width: 760px) {
  .ConsentForm_container {
    max-width: 90%;
  }
}

.check_label {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.75px;
  color: #000000;
}

.check {
  width: 22px;
  height: 22px;
  background: #ffffff;
  border: 1px solid #777777;
  box-sizing: border-box;
  border-radius: 4px;
}
