.auraFieldSignup-label {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
  font-weight: 400;
  margin-bottom: 0px !important;
}

.auraFieldSignup .withError {
  border-bottom: 1px solid red;
}

.auraFieldSignup input {
  border: none;
  border-bottom: 1px solid #999999;
  padding: 0.5rem 0;
  width: 100%;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.408px;
  color: #000000;
  font-weight: 600;
}

.auraFieldSignup input:disabled,
.auraFieldSignup input.disabled {
  color: mid-gray;
  cursor: not-allowed;
}

.auraFieldSignup input.withError {
  border-bottom: 1px solid red;
}

.auraFieldSignup input:focus {
  outline: none;
}

.auraFieldSignup input::placeholder {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.408px;
  color: #999999;
}

.auraFieldSignup div span {
  color: red;
  font-size: 0.8rem;
  font-weight: 600;
}

.auraFieldSignup span {
  color: red;
  font-size: 0.8rem;
  font-weight: 600;
}

.auraFieldSignupError {
  color: red;
  font-size: 1rem;
  font-weight: 600;
}
