@import '../../index.css';
@import '../../fonts.css';

.react-calendar{
    border: none !important;
}

.react-calendar__navigation{
    display: none !important;
}

.react-calendar__tile.react-calendar__month-view__days__day{
    border: 1.2px solid #DFDFDF;
    margin-top: -1px;
    margin-left: -1px;
}

abbr[data-original-title], abbr[title] {
    text-decoration: none !important;
    font-style: normal;
    font-weight: 600;
    font-size: 14.7px;
    line-height: 18px;
    font-feature-settings: 'tnum' on, 'lnum' on;

}

.schedule {
    background-color: orange;
}

.react-calendar__month-view__weekdays {
    text-transform: none !important;
}

.react-calendar__month-view__days__day--weekend {
    color: black !important;
}

.react-calendar__month-view__days__day--neighboringMonth {
    color: black !important;
}

.react-calendar__tile {
    color: black !important;
}

.nav-container {
    display: flex;
    justify-content: space-between;
    width: 48px;
}

.calendar-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 30px;

}

.nav-container img{
    max-width: 9px;
}

.nav-container img:hover{
    color: rgb(163, 163, 163);
    opacity: 0.5;
}

.calendar-header span{
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
}

.react-calendar {
    width: 100%;
}