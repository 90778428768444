.container {
  padding: 1rem;
}
.title {
  font-family: Arial;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 24px;
  letter-spacing: 0.75px;
  color: #000000;
}
.subTitle {
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: -0.32px;
  color: #777777;
}
.familyGrid {
  margin-top: 0.5 rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(5, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
}

.familyCard {
  background: #f3f3f3;
  border-radius: 8px 8px 0px 0px;
  display: flex;
  flex-direction: column;
}

.familyCardTop {
  flex-direction: column;
  display: flex;
  padding: 1rem;
}

.familyCardBottomRight {
  display: flex;
  flex-direction: row;
}

.familyCardPrimary {
  border-left: #ff9900 solid 5px;
  border-bottom-left-radius: 8px;
}

.familyCardLeft {
  flex: 4;
}

.familyCardRight {
  flex: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: fit-content;
}

.name {
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 28px;
  /* identical to box height, or 127% */

  letter-spacing: 0.8px;
  margin-top: 0.2rem;
}

.primaryAccount {
  font-style: italic;
  font-weight: normal;
  font-size: 16px;
  color: #777777;
}

.dob {
  font-weight: normal;
  font-size: 16px;
  color: #777777;
}

.familyCardButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: fit-content;
  padding: 1rem;
}
.historyButton {
  margin-right: 1rem;
  cursor: pointer;
}
.addNewSymptomButton {
  cursor: pointer;
}
.modalBody {
  padding: 0rem;
}
.modalTitle {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 0;
}
.modalSubtitle {
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  text-align: center;
  letter-spacing: -0.408px;
  color: #000000;
  margin-inline: 20%;
  margin-top: 1.2rem;
}
.modalHeader {
  justify-content: flex-start !important;
}
.closeButton {
  margin-right: 2rem;
  cursor: pointer;
}
.modal {
  border-radius: 90px;
}
.symptomsList {
  padding-inline: 5%;
}
