@import "../../fonts.css";
@import "../../index.css";

.insurance-header {
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.75px;
  color: #000000;
}

.not-listed {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.24px;
  color: #777777;
}

.check {
  width: 22px;
  height: 22px;
  background: #ffffff;
  border: 1px solid #777777;
  box-sizing: border-box;
  border-radius: 4px;
}

.selected-button {
  height: 31px;
  background: linear-gradient(
      0deg,
      rgba(255, 153, 0, 0.8),
      rgba(255, 153, 0, 0.8)
    ),
    #ffffff;
  border: 1px solid #ff9900;
  box-sizing: border-box;
  border-radius: 100px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: -0.32px;
  color: #000000;
  cursor: pointer;
  padding: 5px 11px;
  width: 164px;
}

.unSelected-button {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  letter-spacing: -0.32px;
  color: #000000;
  padding: 5px 11px;
  width: 164px;
  height: 31px;
  background: #e3e3e3;
  border-radius: 100px;
  cursor: pointer;
}

.insurance-input {
  border: none;
  border-bottom: 1px solid #777777;
  padding: 0.5rem 0;
  width: 100%;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.408px;
  color: #000000;
  font-weight: 600;
  outline: none;
  /* padding-right: 40px; */
}

.insurance-input input:focus {
  outline: none;
}

.input-image {
  padding: 12px;
  cursor: pointer;
  height: 46px;
  background: #ffffff;
  border: 1.5px dashed #d9d9d9;
  box-sizing: border-box;
  border-radius: 14px;

  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: -0.408px;
  color: #000000;
}

.insurance-fileImage {
  width: 100%;
  height: 100%;
}

.InsuranceForm__continue {
  height: 46px;
  cursor: pointer;
  background: linear-gradient(
      0deg,
      rgba(255, 153, 0, 0.8),
      rgba(255, 153, 0, 0.8)
    ),
    #ffffff;
  border: 1.25px solid #ff9900;
  box-sizing: border-box;
  border-radius: 14px;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: -0.408px;
  color: #000000;
}
.replacebutton {
  margin-left: 1rem;
  cursor: pointer;
}

.addInsuranceContainer {
  background: #f3f3f3;
  border-radius: 8px;
  display: flex;
  padding: 1rem;
  margin-top: 30px;
  align-items: center;
}
.addInsuranceText {
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 20px;
  letter-spacing: -0.24px;
  color: #777777;
  padding-left: 20px;
}

.insuranceText {
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 20px;
  letter-spacing: -0.24px;
  color: #777777;
  padding-left: 20px;
}

@media (max-width: 600px) {
  .insuranceText {
    font-size: 20px;
  }
}
.cardImage {
  width: 400px !important;
}


.breadCrumbs {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.75px;
  color: #8b8b8b;
  margin-left: 4rem;
}

.default {
  display: inline;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.75px;
}

.warn-label{
  background: #FFE794;
  border-radius: 100px;
  padding: 5px 12px 5px 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  color: #000000;
}
