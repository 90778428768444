@import "../../index.css";

.logo {
  grid-area: logo;
  min-width: 296px;
  text-align: center;
  border-bottom: 1px solid var(--light-gray-color);
  border-right: 2px solid var(--light-gray-color);
  margin-bottom: -1px;
}

.header {
  grid-area: header;
  background: var(--white) !important;
  display: flex;
  align-items: center;
}

.sidebar {
  grid-area: sidebar;
  border-top: 1px solid var(--light-gray-color);
  border-right: 2px solid var(--light-gray-color);
}

.content {
  grid-area: content;
  background: var(--white) !important;
  padding: 0 !important;
}

.gridContainer {
  display: grid;
  grid-template-areas:
    "logo header header header header header"
    "sidebar content content content content content";
  grid-template-rows: auto 1fr;
  grid-template-columns: auto 1fr;
  grid-gap: 1px;
  /* background-color: var(--light-gray-color); */
  width: 100vw;
  min-height: 100vh;
}

.gridContainer > div {
  background-color: var(--gray-color);
  padding: 20px 0;
}

.header-title {
  padding-left: 35px;
}

.btn.btn-orange {
  background: rgba(255, 153, 0, 0.8);
  border: 1.25px solid #ff9900;
  border-radius: 14px;
  height: 48px;
  width: 327px;
  color: #000;
  margin-left: auto;
  margin-right: auto;
}

.btn.btn-orange-med {
  background: rgba(255, 153, 0, 0.8);
  border: 1.25px solid #ff9900;
  border-radius: 14px;
  height: 48px;
  width: 200px;
  color: #000;
  margin-left: auto;
  margin-right: auto;
}

.btn.btn-orange-gray {
  background: #f3f3f3;
  border: 1.25px solid #f3f3f3;
  border-radius: 14px;
  height: 48px;
  width: 200px;
  color: #000;
  margin-left: auto;
  margin-right: auto;
}

p.modal-content-text {
  font-family: Inter;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.40799999237060547px;
  text-align: left;
}

.child-header {
  font-family: Inter;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.40799999237060547px;
  text-align: center;
}

.child-header-container {
  height: 99px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ff77c0;
  border-radius: 4px 4px 8px 8px;
}

.modal-header-2 {
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.23999999463558197px;
  text-align: left;
  color: #777;
}

img.family-image {
  position: absolute;
  left: 0%;
  right: 0;
}

.preview-container {
  height: 155px;
  background: #e6e6e6;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.preview-image {
  height: 140px;
  width: 100px;
}

.header > div {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: flex-end;
  padding-right: 58px;
}

.header-right-title {
  /* font-family: SF Pro Text; */
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  letter-spacing: -0.32px;
  color: #5f5f5f;
  margin: 0;
  cursor: pointer;
}

.back-icon {
  width: 17px;
  margin-right: 15px;
  padding-bottom: 5px;
  cursor: pointer;
}

.unsigned-family-consent {
  font-family: Inter;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.40799999237060547px;
  text-align: center;
}

.unsigned-family-consent-header-container {
  height: 99px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ff77c0;
  /* border-radius: 4px 4px 8px 8px; */
}

.mobile-nav {
  height: 100vh;
}

.mobile-nav .content {
  height: 100%;
}

@media (max-width: 1024px) {
  .header-title {
    padding-left: 50px;
    /* padding-right: 50px; */
  }

  .header > div {
    padding-right: 50px;
  }
}

@media (max-width: 768px) {
  .header-title {
    padding-left: 25px;
    /* padding-right: 25px; */
    font-size: 24px !important;
  }

  .header-right-title {
    padding-right: 25px;
  }

  .header > div {
    padding-right: 25px;
  }

  .back-icon {
    width: 12px;
  }
}

.under13 .modal-dialog {
  max-width: 360px;
  margin-top: 5.5rem;
}

@media (max-width: 375) {
  .modal-dialog {
    max-width: 360px;
  }
}
