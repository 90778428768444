.headerText {
    font-style: normal;
    font-weight: normal;
    font-size: 28px;
    line-height: 34px;
}

.container {
    margin-inline: 3rem;
}

.body {
    background: #F3F3F3;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
}
.bodyLeft {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.bodyRight {
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.bodyHeaderText {
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 22px;
    text-align: center;
}

.bodySubHeaderText {
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 22px;
    /* or 129% */
    letter-spacing: -0.408px;
    /* Typography/Inactive Lable */
    color: #777777;
    text-align: center;
    padding-inline: 1rem;
}

.supportLink {
    background-color: white;
    min-width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-block: 1rem;
    padding-block: 1rem;
    border-radius: 12.7355px;
    border: 0.909677px solid #DFDFDF;
    color: black;
}

.supportIcon {
    max-width: 3rem;
    max-height: 3rem;
}

a { text-decoration: none; }