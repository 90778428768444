@import '../../fonts.css';
@import '../../index.css';

.container {
  display: flex;
  padding-left: 0 !important;
  padding-right: 0 !important;
  height: 100%;
  padding-bottom: 50px;
}

.header {
  background: #F3F3F3;
  padding: 1rem;
}

.name {
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 28px;
  /* identical to box height, or 127% */

  letter-spacing: 0.8px;

  /* Typography/Black */

  color: #000000;
  margin-bottom: 0;
}

.primaryAccount {
  font-style: italic;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  /* or 138% */

  display: flex;
  align-items: flex-end;
  letter-spacing: -0.078px;

  /* Typography/Inactive Lable */

  color: #777777;
}

.subContainer {
  margin-top: 38px;
  margin-left: 35px;
  max-width: 65%;
  border: 2px solid #F3F3F3;
  box-sizing: border-box;
  border-radius: 8px;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 2.5rem;
  max-width: 60vw;
  margin-left: 50px;
  margin-right: -8px;
}

.flex1 {
  flex: 1
}

.contentContainer {
  /* background: #F3F3F3; */
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 25px;
  padding-top: 0;
  margin-top: 10px;
}

.down-20 {
  margin-top: 20px;
}

.w-100 {
  width: 100% !important;
}

.normal-font,
.thin-normal-font {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: -0.408px;
  color: #000000;
  margin: 0;
}

.MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiInputBase-input.MuiInput-input {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: -0.408px;
  color: #000000;
  margin: 0;
}

.customFileUpload {
  display: inline-block;
  cursor: pointer;
  font-weight: 600;
  background-color: #fff;
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  cursor: pointer;
  min-width: -moz-fit-content;
  min-width: fit-content;
  padding: 10px;
  border: 1px black dashed;
}

.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.inputfile+label {
  cursor: pointer;
  /* "hand" cursor */
}

.inputfile:focus+label,
.inputfile+label:hover {
  background-color: red;
}

.inputfile+label {
  font-size: 1.25em;
  font-weight: 700;
  color: white;
  background-color: black;
  display: inline-block;
  margin-top: 3rem;
  padding: 0.5rem;
}

@media (max-width: 1200px) {
  .container {
    flex-direction: column;
  }

  .subContainer {
    margin: 40px;
    margin-left: 35px;
    margin-top: 25px;
  }

  .buttonContainer {
    margin-left: 40px;
    /* margin-right: -24px; */
  }

  .inputNameContainer {
    flex-direction: column;
  }
}

@media (max-width: 1024px) {

  .subContainer {
    margin: 40px;
    margin-top: 25px;
    margin-left: 50px;
    max-width: 80%;
  }
}

@media (max-width: 950px) {
  .buttonContainer {
    margin-left: 24px;
    /* margin-right: 11px; */
  }
}
@media (max-width: 768px) {
  .subContainer {
    margin: 25px;
    max-width: 100%;
  }

  .buttonContainer {
    margin: 25px;
    max-width: 100%;
  }

  .inputNameContainer {
    flex-direction: column;
  }
}

@media (max-width: 576px) {
  .subContainer {
    margin: 5%;
    max-width: 90%;
  }
  .buttonContainer {
    margin: 3%;
  }
}

.saveButton {
  display: flex;
  background: linear-gradient(0deg, rgba(255, 153, 0, 0.8), rgba(255, 153, 0, 0.8)), #FFFFFF;
  border: 1.25px solid #FF9900;
  box-sizing: border-box;
  border-radius: 14px;
  align-items: center;
  text-align: center;
  letter-spacing: -0.408px;
  justify-content: center;
  color: #000000;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  height: 50px;
  width: 100%;
}

.cancelButton {
  width: 100%;
}