.exit-modal {
    top: 30%;
    border-radius: 3rem;
}
  
.exit-modal_content {
    border-radius: 3rem;
}

.exit-modal-header {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.75px;
    color: #000000;
}

.exit-modal-subHeader {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    letter-spacing: -0.32px;
    color: #777777;
}


.exit-modal-cancel:hover {
    border: 3px solid #000000;
}
  
.exit-modal-cancel {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 46px;
    box-sizing: border-box;
    border-radius: 14px;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 22px;
    letter-spacing: -0.408px;
    color: #000000;
    background: #ffffff;
    border: 1.25px solid #000000;
}
