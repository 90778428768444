.auraFielInput-label {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
  font-weight: 400;
  margin-bottom: 0px !important;
}

.auraFielInput .withError {
  border-bottom: 1px solid red;
}

.auraFielInput input {
  border: none;
  border-bottom: 1px solid #999999;
  padding: 0.5rem 0;
  width: 100%;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.408px;
  color: #000000;
  font-weight: 600;
}

.auraFielInput input:disabled,
.auraFielInput input.disabled {
  color: mid-gray;
  cursor: not-allowed;
}

.auraFielInput input.withError {
  border-bottom: 1px solid red;
}

.auraFielInput input:focus {
  outline: none;
}

.auraFielInput input::placeholder {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.408px;
  color: #999999;
}

.auraFielInput div span {
  color: red;
  font-size: 0.8rem;
  font-weight: 600;
}

.auraFielInput span {
  color: red;
  font-size: 0.8rem;
  font-weight: 600;
}

.auraFielInputError {
  color: red;
  font-size: 1rem;
  font-weight: 600;
}

.eyeIcon {
  right: 0;
  top: 0px;
  bottom: 0px;
  padding-right: 3px;
  display: flex;
  align-items: center;
}
