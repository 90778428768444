.container {
    padding: 0px 35px;
}

.header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 40px;
}

.headerItem {
    margin-right: 1rem;
    background: #F5F5F5;
    border-radius: 8px;
    padding: 0.5rem;
    min-width: 4rem;
    text-align: center;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    letter-spacing: -0.32px;
    cursor: pointer;
}
.headerItemSelected {
    margin-right: 1rem;
    background: #EAD9FF;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    letter-spacing: -0.32px;
    color: #7116E0;
    border-radius: 8px;
    padding: 0.5rem;
    min-width: 4rem;
    text-align: center;
    cursor: pointer;
}

.content {
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;
}
.contentLeft {
    width: 35%;
    margin-right: 2rem;
    border: 2px solid #D0B2F5;
    box-sizing: border-box;
    border-radius: 14px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.peopleTextContainer {
    text-align: center;
    justify-content: center;
    margin: 100px 30px;
}

.peopleTextValue {
    display: flex;
    text-align: center;
    margin-bottom: 50px;
    align-items: center;
}
.peopleText {
    font-size: 35px;
    font-weight: 400;
    margin-right: 10px;
}
.peopleTextSub {
    font-size: 48px;
    font-weight: 700;
    margin-right: 20px;
}
.contentMedium {
    width: 25%;
    min-height: 100%;
    margin-right: 2rem;
    display: flex;
    flex-direction: column;
}
.contentMediumTop {
    min-height: 36%;
    border: 2px solid #D5B2FF;
    background-color: #D5B2FF;
    margin-bottom: 30px;
    box-sizing: border-box;
    border-radius: 14px;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.sectionText1 {
    font-size: 34px;
}
.sectionText2 {
    font-size: 20px;
    padding: 5px 20px;
    background-color: #fff;
    border-radius: 100px;
    width: max-content;
}
.sectionTextRight2 {
    font-size: 20px;
    padding: 5px 20px;
    background-color: #fff;
    border-radius: 100px;
    width: max-content;
    float: right;
}
.mediumTopBottomSection {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    text-align: end;
}
.headingText1 {
    font-size: 22px;
    color: #595959;
}
.headingText2 {
    font-weight: 700;
    font-size: 28px;
}
.mediumBottomSection {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
}
.contentMediumBottom {
    min-height: 60%;
    border: 2px solid #CCB8E5;
    background: #CCB8E5;
    box-sizing: border-box;
    border-radius: 14px;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.contentRight {
    width: 25%;
    box-sizing: border-box;
    border-radius: 14px;
    display: flex;
    flex-direction: column;
}
.contentRightTop {
    min-height: 60%;
    border: 2px solid #E7D4FF;
    background-color: #E7D4FF;
    margin-bottom: 30px;
    box-sizing: border-box;
    border-radius: 14px;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: end;
}
.rightTopBottomSection {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    align-content: flex-end;
}
.rightBottomSection {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    align-content: flex-end;
}
.contentRightBottom {
    min-height: 36%;
    border: 2px solid #EADFF7;
    background: #EADFF7;
    box-sizing: border-box;
    border-radius: 14px;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: end;
}

@media (max-width: 705px) {
    .content {
        display: flex;
        flex-direction: column;
    }
    .contentLeft {
        width: 100%;
    }
    .contentMedium {
        width: 100%;
        margin: 30px 0;
        margin-right: 0;
    }
    .contentRight {
        width: 100%;
        margin-bottom: 30px;
        margin-right: 0;
    }
    
}