
.buttonContainer {
  display: flex;
  height: 48px;
  align-items: center;
  margin-left: 30px;
  margin-right: 30px;
  padding-left: 20px;
  border-radius: 8px;
  margin-bottom: 15px;
}

.subButtonContainer {
  display: flex;
  height: 48px;
  align-items: center;
  margin-left: 30px;
  margin-right: 30px;
  padding-left: 20px;
  border-radius: 8px;
  text-decoration: none !important;
}

.selected {
  background-color: #F2F2F2;
}

.selected span {
  color: #000000 !important;
  font-weight: 600;
}

.buttonContainer span {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 12px;
  margin-left: 15px;
  color: #777777;
}

.subButtonContainer > span {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 12px;
  margin-left: 15px;
  color: #777777;
}