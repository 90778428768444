@import '../../fonts.css';
@import '../../index.css';

.container {
  display: flex;
  padding-left: 0 !important;
  padding-right: 0 !important;
  height: 100%;
  padding-bottom: 50px;
}

.subContainer {
  margin-top: 50px;
  margin-left: 58px;
}

.flex1 {
  flex: 1
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header > p {
  cursor: pointer;
}

.contentContainer {
  background: #F3F3F3;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 25px;
  padding-top: 0;
  margin-top: 10px;
}

@media (max-width: 1024px) {
  .container {
    flex-direction: column;
  }

  .subContainer {
    margin: 50px;
  }
}

@media (max-width: 768px) {
  .subContainer {
    margin: 25px;
    width: 400px;
  }

  .flex1 {
    display: flex;
    justify-content: center;
  }
}