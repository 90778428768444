@import '../../index.css';

.container {
  background: #FFFFFF;
  border: 1px solid #A3A3A3;
  box-sizing: border-box;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  padding: 14px;
}

.container > p {
  text-align: center;
}

.smallSubFont {
  font-size: 10px;
}

.divider {
  width: 1px;
  height: 20px;
  margin-left: 20px;
  margin-right: 20px;
  background-color: #DCDCDC;
}

.scan {
  margin-top: 10px;
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .container {
    align-self: baseline;
    margin-top: 45px;
  }
}

.title {
  font-size: 20px;
  color: #000000;
  font-weight: bold;
}

