@import '../../fonts.css';
@import '../../index.css';

.container {
  display: flex;
  padding-left: 0 !important;
  padding-right: 0 !important;
  height: 100%;
  padding-bottom: 50px;
}

.subContainer {
  margin-top: 50px;
  margin-left: 58px;
  max-width: 900px;
  width: 100%;
}

.form_header {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.24px;
  color: #000000;
}

.flex1 {
  flex: 1;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header > p {
  cursor: pointer;
}

.contentContainer {
  background: #F3F3F3;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 25px;
  padding-top: 0;
  margin-top: 10px;
}

.insuranceCardContainer {
  display: flex;
  padding-top: 0;
  margin-top: 10px;
}

.insuranceCard {
  max-width: 90%;
  max-height: 90%;
}

@media (max-width: 1024px) {
  .container {
    flex-direction: column;
  }

  .subContainer {
    margin: 50px;
  }

  .insuranceCard {
    max-width: 100%;
    max-height: 100%;
  }
}

@media (max-width: 768px) {
  .subContainer {
    margin: 25px;
    width: 400px;
  }

  .flex1 {
    display: flex;
    justify-content: center;
  }

  .insuranceCard {
    max-width: 100%;
    max-height: 100%;
  }
}

.noVaccinationsContainer {
  border: 1px solid #777777;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.syringe {
  max-width: 20%;
  max-height: 20%;
}

.noVaxTitle {
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 33px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.87px;

  /* Typography/Black */

  color: #000000;
}
.noVaxText {
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 33px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.87px;

  /* Typography/Black */

  color: #000000;
}
.noVaxSubtitle {
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  text-align: center;
  letter-spacing: -0.408px;
  color: #595959;
  margin-top: 1rem;
}

.noVaxSubtitle2 {
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 22px;
  text-align: center;
  letter-spacing: -0.408px;
  color: #595959;
}
.noVaxSubtitle3 {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.078px;
  margin-top: 1rem;
  color: #777777;
}


.sideMenu {
  display: block;
}
.sideMenu2 {
  display: none;
}

@media (max-width: 420px) {
  .sideMenu {
    display: none;
  }
  .sideMenu2 {
    display: block;
  }
}

.cdcLink {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  letter-spacing: -0.32px;
  color: #2043F8;
}
.uploadNewDoseButton {
  border: 1.5px dashed #D9D9D9 !important;
  box-sizing: border-box;
  border-radius: 14px !important;
}

.vaxCard {
  margin-block: 1rem;
}

.vaxCardBody {
  display: flex;
  flex-direction: row;
  flex: 1;
}

@media (max-width: 992px) {
  .vaxCardBody {
    flex-direction: column;
  }

  .vaxCardBodyRight{
    margin-top: 10px;
  }
  .form {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
}

.tabText {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.75px;
  color: #8b8b8b;
}

.vaxCardHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #F3F3F3;
  border-radius: 8px 8px 0px 0px;
  padding: .5rem;
}
.vaxCardHeaderLeft {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.vaxCardHeaderText {
  margin-left: 1rem;
  margin-bottom: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  /* identical to box height, or 129% */

  letter-spacing: -0.408px;

  /* Typography/Black */

  color: #000000;
}
.editButton {
  position: absolute;
  top: .25rem;
  right: 1rem;
  z-index: 99;
  cursor: pointer;
}
.vaxCardBodyLeft {
  position: relative;
  display: flex;
  flex: 2;
  border-bottom: 1px solid #f0f0f0;
}
.vaxCardBodyRight {
  display: flex;
  flex: 2;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #FAFAFA;
  border-radius: 0px 8px 0px 0px;
}
.formInner {
  padding-top: 1rem;
}
.form {
  width: 100%;
  margin-right: 1rem;
  margin-left: 1rem;
}
.uploadPhotoButton {
  cursor: pointer;
}

.inputfile {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

.inputfileerror {
  color: red;
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

.inputfile + label {
	cursor: pointer; /* "hand" cursor */
}

.inputfile:focus + label,
.inputfile + label:hover {
    background-color: red;
}

.imagePreview {
  display: flex;
  flex-direction: row;
}

.saveButtonDisabled {
  background: linear-gradient(0deg, rgba(255, 153, 0, 0.8), rgba(255, 153, 0, 0.8)), #FFFFFF;
  opacity: 0.4;
  /* Aura Orange/Primary */

  border: 1.25px solid #FF9900;
  box-sizing: border-box;
  border-radius: 14px;
  width: 100%;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  padding-block: 0.5rem;
  text-align: center;
  cursor: not-allowed;
}
.saveButtonDisabled:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.saveButton:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.VaccinationCompleted{
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 38px;
  text-align: center;
  letter-spacing: 0.374px;
  color: #000000;
}

@media (max-width: 768px) {
  .VaccinationCompleted {
    font-weight: 600;
    font-size: 22px;
    line-height: 21px;
  }
}

.whiteButton {
  box-sizing: border-box;
  padding: 12px;
  height: 46px;
  background: #FFFFFF;
  border: 1.25px solid #777777;
  border-radius: 14px;
  width: 100%;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  text-align: center;
  letter-spacing: -0.408px;
  color: #000000;
  cursor: pointer;
}

.vaccineCardContainer {
  min-width: 60%;
  max-width: 60%;
}

.vaccineCardContainer2 {
  min-width: 70%;
  max-width: 70%;
}


@media (max-width: 800px) {
  .vaccineCardContainer2 {
    min-width: 90%;
    max-width: 90%;
  }
}

.name_header {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.75px;
  color: #000000;
}

@media (max-width: 576px) {
  .vaccineCardContainer {
    min-width: 90%;
    max-width: 90%;
  }
}

.add_vaccine {
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 22px;
  text-align: center;
  letter-spacing: -0.408px;
  color: #777777;
}

.saveButton {
  background: linear-gradient(0deg, rgba(255, 153, 0, 0.8), rgba(255, 153, 0, 0.8)), #FFFFFF;
  /* Aura Orange/Primary */
  
  border: 1.25px solid #FF9900;
  box-sizing: border-box;
  border-radius: 14px;
  width: 100%;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  padding-block: 0.5rem;
  text-align: center;
  cursor: pointer;
}