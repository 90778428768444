@import "../../index.css";

.forget_form {
  width: 35%;
}

@media (max-width: 1400px) {
  .forget_form {
    width: 50%;
  }
}

@media (max-width: 992px) {
  .forget_form {
    width: 60%;
  }
}

@media (max-width: 860px) {
  .forget_form {
    width: 70%;
  }
}

@media (max-width: 650px) {
  .forget_form {
    width: 90%;
  }
}

.alertText {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.24px;
  color: #000000;
}

.forget_form_header {
  /* font-family: SF Pro Display; */
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 33px;
  letter-spacing: 0.87px;
  color: #000000;
}

.forget_form_header_des {
  /* font-family: SF Pro Text; */
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: -0.408px;
  color: #777777;
}

.btn_reset_ {
  outline: none;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  width: 100%;
  background: linear-gradient(
      0deg,
      rgba(255, 153, 0, 0.8),
      rgba(255, 153, 0, 0.8)
    ),
    #ffffff;
  border: 1.25px solid #ff9900;
  box-sizing: border-box;
  border-radius: 14px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px;
  margin-top: -5px;
  height: 46px;
}

.btn_reset_:focus {
  border: 2px solid #ff9900;
  outline: none;
}

.goBack {
  cursor: pointer;
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 14px !important;
  width: 100% !important;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  color: #000 !important;
  padding: 4px !important;
  height: 46px !important;
  box-shadow: none !important;
  outline: none !important;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: -0.408px;
  border: 1.25px solid #a3a3a3;
}
