.aura-checkbox input {
  margin-right: 3px;
}
.aura-checkbox-container .aura-checkbox label {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.078px;
  color: #000000;
}

.error {
  color: red;
  font-size: .8rem;
  font-weight: 600;
}

.auraField .withError {
  border-bottom: 1px solid red;
}

/* .aura-checkbox-container {
  margin: 1rem 0;
}
.aura-checkbox-container .aura-checkbox {
  display: flex;
  margin-bottom: 1rem;
}
.aura-checkbox-container .aura-checkbox input {
  appearance: none;
  background: white;
  border: 1px solid gray;
  cursor: pointer;
  flex-shrink: 0;
  height: 1.3rem;
  outline: none;
  position: relative;
  width: 1.3rem;
  margin-right: .5rem;
}
.aura-checkbox-container .aura-checkbox input:focus {
  outline: none;
}
.aura-checkbox-container .aura-checkbox input::before {
  background: white;
  bottom: 50%;
  content: ' ';
  height: 1.2rem;
  left: 50%;
  position: absolute;
  right: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  transition: all 0.1s;
  width: 1.2rem;
}
.aura-checkbox-container .aura-checkbox input:checked::before {
  background: grey;
}
*/
