@import "../../fonts.css";
@import "../../index.css";

.container {
  display: flex;
  flex-direction: column;
  padding-left: 0 !important;
  padding-right: 0 !important;
  /* height: 100%; */
  padding-bottom: 50px;
}

.subContainer {
  margin-top: 38px;
  margin-left: 35px;
  max-width: 65%;
  border: 2px solid #f3f3f3;
  box-sizing: border-box;
  border-radius: 8px;
}

.flex1 {
  flex: 1;
}

.contentContainer {
  border-radius: 8px;
  padding: 25px;
  padding-top: 0;
  margin-top: 10px;
}

@media (max-width: 1024px) {
  .container {
    flex-direction: column;
  }

  .subContainer {
    margin: 40px;
    margin-top: 25px;
    margin-left: 50px;
    max-width: 80%;
  }
}

@media (max-width: 768px) {
  .subContainer {
    margin: 25px;
    max-width: 100%;
  }
}

@media (max-width: 400px) {
  .subContainer {
    margin: 15px;
    max-width: 370px;
  }
}

.header {
  background: #f3f3f3;
  padding: 1rem;
}

.name {
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 28px;
  /* identical to box height, or 127% */

  letter-spacing: 0.8px;

  /* Typography/Black */

  color: #000000;
  margin-bottom: 0;
}

.primaryAccount {
  font-style: italic;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  /* or 138% */

  display: flex;
  align-items: flex-end;
  letter-spacing: -0.078px;

  /* Typography/Inactive Lable */

  color: #777777;
}

.editProfileButton {
  border: 1.5px dashed #bfbfbf !important;
  box-sizing: border-box;
  border-radius: 14px !important;
}

.signOut {
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  letter-spacing: 0.8px;
  margin-top: 1.5rem;
  cursor: pointer;
  color: #2043f8;
}

.signOutImage {
  margin-right: 1rem;
}