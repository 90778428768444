.container {
  padding-top: 15%;
  margin: auto;
  display: flex;
  flex-direction: column;
}

.welcomeText {
  display: flex;
  margin: auto;
  font-weight: 700;
  font-size: 22px;
  padding-bottom: 0.75rem;
}

.auraLogo {
  display: flex;
  width: 250px;
  margin: auto;
}
