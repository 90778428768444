@import "../../fonts.css";
@import "../../index.css";

.container {
  padding-right: 10%;
  height: 100%;
}

.flex1 {
  flex: 1;
}

.listContainer {
  /* padding-left: 58px;
  padding-right: 58px; */
  padding: 15px 0 10px 10px;
}

.dateContainer {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.dateContainer img {
  margin-left: 5px;
}

.sideContainer {
  border-left-width: 1px;
  border-color: var(--light-gray-color);
  border-left-style: solid;
}

.resultDetailContainer {
  margin-top: 30px;
  text-align: center;
}

@media (max-width: 1024px) {
  .container {
    flex-direction: column;
  }

  .sideContainer {
    border-left-style: none;
  }

  .sideContentContainer {
    margin-left: 54px;
    margin-right: 54px;
  }

  .titleContainer {
    margin-left: 54px;
  }

  .container1 {
    padding: 0px;
    padding-top: 0;
  }

  .flex1 {
    flex: 0;
  }
}

@media (max-width: 768px) {
  .sideContentContainer {
    margin-left: 25px;
    margin-right: 25px;
  }

  .titleContainer {
    margin-left: 25px;
  }

  .container1 {
    padding: 0px;
    padding-top: 0;
  }

  .flex1 {
    flex: 0;
  }
}
.sorter {
  border: 1px solid #777777;
  box-sizing: border-box;
  border-radius: 30px;
  min-width: 4rem;
  padding: 0.5rem 4rem 0.5rem 0.75rem;
  display: flex;
  flex-direction: row;
  cursor: pointer;
}
.sorterText {
  margin-bottom: 0rem;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.75px;
  color: #777777;
  margin-right: 0.75rem;
}
.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.headerText {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
}
